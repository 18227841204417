import React, { useState } from 'react';
import { Container, Card, Form, Button, Alert } from 'react-bootstrap';
import logo from '../assets/logos/FullLogo_Transparent_NoBuffer.png';

export default function MFASetup({ onSetupComplete }) {
   const [qrCode, setQrCode] = useState('');
   const [verificationCode, setVerificationCode] = useState('');
   const [error, setError] = useState('');

   // Stili consistenti con Login
   const cardBackgroundColor = 'linear-gradient(90deg, rgba(232,239,242,1) 0%, rgba(227,233,237,1) 39%, rgba(215,221,224,1) 100%)';
   const activeOuterBorderColor = '#18303e';

   // Richiedi QR code dal server
   const getQRCode = async () => {
       try {
           const response = await fetch('/api/enable-2fa/', {
               method: 'POST',
               headers: {
                   'Authorization': `Token ${localStorage.getItem('temp_token')}`,
               }
           });
           const data = await response.json();
           setQrCode(data.qr_code);
       } catch (err) {
           setError('Errore durante la generazione del QR code');
       }
   };

   // Verifica codice inserito
   const verifyCode = async () => {
       try {
           const response = await fetch('/api/verify-2fa/', {
               method: 'POST',
               headers: {
                   'Authorization': `Token ${localStorage.getItem('temp_token')}`,
                   'Content-Type': 'application/json'
               },
               body: JSON.stringify({ code: verificationCode })
           });
           
           if (response.ok) {
               const data = await response.json();
               localStorage.setItem('token', data.token);
               onSetupComplete();
           } else {
               setError('Codice non valido');
           }
       } catch (err) {
           setError('Errore durante la verifica');
       }
   };

   return (
       <Container className='w-90 mt-5'>
           <Card style={{
               border: `2.8px solid ${activeOuterBorderColor}`,
               background: cardBackgroundColor,
               width: '100%'
           }}>
               <img 
                   src={logo}
                   alt="Company Logo" 
                   style={{ width: '200px', margin: '10px auto', display: 'block' }}
               />
               
               <Card.Header style={{ backgroundColor: '#18303e', color: 'white'}}>
                   <h2 className='mb-3'>Configurazione Google Authenticator</h2>
               </Card.Header>

               <Card.Body>
                   {!qrCode ? (
                       <Button 
                           onClick={getQRCode}
                           style={{ backgroundColor: '#15253f', borderColor: '#15253f' }}
                       >
                           Inizia Configurazione
                       </Button>
                   ) : (
                       <>
                           <div className="text-center mb-4">
                               <img 
                                   src={`data:image/png;base64,${qrCode}`} 
                                   alt="QR Code"
                                   style={{ width: '180px', height: '180px' }} // Adjust the size here
                               />
                               <p style={{ marginTop: '20px' }}>
                                   Scansiona questo codice con Google Authenticator
                               </p>
                           </div>
                           
                           <Form>
                               <Form.Group>
                                   <Form.Label style={{ fontWeight: 'bold' }}>
                                       Codice di Verifica
                                   </Form.Label>
                                   <Form.Control
                                       type="text"
                                       value={verificationCode}
                                       onChange={(e) => setVerificationCode(e.target.value)}
                                       placeholder="Inserisci il codice"
                                       style={{ backgroundColor: '#fbfcfe' }}
                                   />
                               </Form.Group>
                               <Button 
                                   onClick={verifyCode}
                                   className="mt-3"
                                   style={{ backgroundColor: '#15253f', borderColor: '#15253f' }}
                               >
                                   Verifica
                               </Button>
                           </Form>
                       </>
                   )}
                   
                   {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
               </Card.Body>
           </Card>
       </Container>
   );
}