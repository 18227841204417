import * as actions from './actionTypes'      // Tipi di azioni per Redux
import axios from 'axios'                     // Client HTTP per le chiamate API
import { variables } from '../../Variables';   // Variabili di configurazione (es. URL API)

// Configurazione di sicurezza per l'ambiente di produzione
// Questi setting sono cruciali per prevenire attacchi CSRF
axios.defaults.xsrfCookieName = 'csrftoken';        // Nome del cookie CSRF che Django genera
axios.defaults.xsrfHeaderName = 'X-CSRFToken';      // Header che conterrà il token CSRF
axios.defaults.withCredentials = true;              // Abilita l'invio di credenziali nelle richieste

// Action creator per l'inizio del processo di autenticazione
export const authStart = (username)=> {
    return{
        type: actions.AUTH_START,
        user: username
    }
}

// Action creator per autenticazione completata con successo
// Converte esplicitamente tutti i flag di permesso in booleani
export const authSuccess = (token, username, is_superuser, is_crcheck, is_finrisk, is_finbil, is_ownfunds, is_sdvcheck, is_pillar3) => {
    return {
        type: actions.AUTH_SUCCESS,
        token: token,
        user: username,
        is_superuser: Boolean(is_superuser),  // Conversione esplicita in boolean
        is_crcheck: Boolean(is_crcheck),      // Conversione esplicita in boolean
        is_finrisk: Boolean(is_finrisk),      // Conversione esplicita in boolean
        is_finbil: Boolean(is_finbil),         // Conversione esplicita in boolean
        is_ownfunds: Boolean(is_ownfunds),         // Conversione esplicita in boolean
        is_sdvcheck: Boolean(is_sdvcheck),         // Conversione esplicita in boolean
        is_pillar3: Boolean(is_pillar3)         // Conversione esplicita in boolean

    };
};

// Action creator per gestire il fallimento dell'autenticazione
export const authFail = error=> {
    return{
        type: actions.AUTH_FAIL,
        error: error
    }
}

// Gestisce il timeout dell'autenticazione
// Fa il logout automatico dopo il tempo specificato
export const checkAuthTimeout = expTime => {
    return dispatch => {
        setTimeout(()=> {
            dispatch(logout())
        }, expTime*1000)  // Converte il tempo da secondi a millisecondi
    }
}

// Action creator per il logout
// Rimuove tutti i dati di autenticazione dal localStorage
export const logout = () => {
    localStorage.removeItem('user')
    localStorage.removeItem('is_superuser')
    localStorage.removeItem('expirationDate')
    localStorage.removeItem('token')
    localStorage.removeItem('pagesHome')
    localStorage.removeItem('pagesComp')
    return {
        type: actions.AUTH_LOGOUT
    }
}

// Gestisce il processo di login
// È una funzione asincrona che gestisce la chiamata API di login
export const authLogin = (username, password, navigate) => {
    return async dispatch => {
        console.log("Login started with username:", username);
        dispatch(authStart(username));
        
        try {
            // Effettua la chiamata API di login con le credenziali
            const res = await axios.post(variables.API_URL + 'dj-rest-auth/login/', 
                {
                    username: username,
                    password: password
                },
                {
                    withCredentials: true,  // Importante per la gestione dei cookie
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json'
                    }
                }
            );
            
            console.log("Full login response:", res.data);
            
            // Controlla se è necessaria l'autenticazione a due fattori
            if (res.data.require_mfa_setup || res.data.require_2fa) {
                return res.data;  // Ritorna i dati per gestire il flusso MFA
            }

            // Estrae e converte i dati dell'utente
            const token = res.data.key;
            const is_superuser = Boolean(res.data.user.is_superuser);
            const is_crcheck = Boolean(res.data.user.is_crcheck);
            const is_finrisk = Boolean(res.data.user.is_finrisk);
            const is_finbil = Boolean(res.data.user.is_finbil);
            const is_ownfunds = Boolean(res.data.user.is_ownfunds);
            const is_sdvcheck = Boolean(res.data.user.is_sdvcheck);
            const is_pillar3 = Boolean(res.data.user.is_pillar3);
            
            console.log("User permissions:", {
                is_superuser,
                is_crcheck,
                is_finrisk,
                is_finbil,
                is_ownfunds,
                is_sdvcheck,
                is_pillar3
            });
            
            // Calcola la data di scadenza del token (1 ora)
            const expDate = new Date(new Date().getTime() + 3600 * 1000);
            
            // Salva i dati dell'utente nel localStorage
            localStorage.setItem('user', username);
            localStorage.setItem('expirationDate', expDate);
            localStorage.setItem('token', token);
            localStorage.setItem('is_superuser', is_superuser);
            localStorage.setItem('is_crcheck', is_crcheck);
            localStorage.setItem('is_finrisk', is_finrisk);
            localStorage.setItem('is_finbil', is_finbil);
            localStorage.setItem('is_ownfunds', is_ownfunds);
            localStorage.setItem('is_sdvcheck', is_sdvcheck);
            localStorage.setItem('is_pillar3', is_pillar3);

            
            // Dispatcha l'azione di successo
            dispatch(authSuccess(token, username, is_superuser, is_crcheck, is_finrisk, is_finbil, is_ownfunds, is_sdvcheck, is_pillar3));
            navigate('/home');  // Reindirizza alla home
            dispatch(checkAuthTimeout(3600));  // Imposta il timeout di 1 ora
            
            return res.data;
        } catch (error) {
            // Gestione dettagliata degli errori con logging
            console.log("Full error object:", error);
            if (error.response) {
                // Errore del server con risposta
                console.log("Login failed with response data:", error.response.data);
                console.log("Login failed with status:", error.response.status);
                console.log("Login failed with headers:", error.response.headers);
                dispatch(authFail(error.response.data));
            } else if (error.request) {
                // Nessuna risposta ricevuta
                console.log("Login failed, no response received:", error.request);
                dispatch(authFail("No response received from server"));
            } else {
                // Errore nella configurazione della richiesta
                console.log("Login failed, error message:", error.message);
                dispatch(authFail(error.message));
            }
            throw error;
        }
    };
};

// Gestisce il processo di registrazione
// Include la gestione della chiave di attivazione
export const authSignup = (username, email, password1, password2, key, navigate) => {
    return dispatch => {
        console.log("Signup started with username:", username);
        dispatch(authStart(username));

        return new Promise((resolve, reject) => {
            axios.post(variables.API_URL + 'dj-rest-auth/registration/', {
                username: username,
                email: email,
                password1: password1,
                password2: password2,
                key: key  // Chiave di attivazione necessaria per la registrazione
            }).then(res => {
                console.log("Signup success:", res.data);
                if (res.data && res.data.user) {
                    dispatch(authSuccess(res.data.user.username, res.data.user.email, false));
                    if (navigate) {
                        navigate('/login');
                    }
                    resolve(res.data);
                } else {
                    console.log("Unexpected response format:", res.data);
                    const error = new Error("Unexpected response format");
                    dispatch(authFail(error.message));
                    reject(error);
                }
            }).catch(error => {
                // Gestione dettagliata degli errori di registrazione
                if (error.response) {
                    console.log("Signup failed with response data:", error.response.data);
                    dispatch(authFail(error.response.data));
                } else if (error.request) {
                    console.log("Signup failed, no response received:", error.request);
                    dispatch(authFail("No response received from server"));
                } else {
                    console.log("Signup failed, error message:", error.message);
                    dispatch(authFail(error.message));
                }
                reject(error);
            });
        });
    };
};

// Verifica lo stato di autenticazione all'avvio dell'applicazione
// Controlla se l'utente ha già un token valido nel localStorage
export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token')
        const username = localStorage.getItem('user')
        // Converte i valori stringa 'true'/'false' in booleani
        const is_superuser = localStorage.getItem('is_superuser') === 'true';
        const is_crcheck = localStorage.getItem('is_crcheck') === 'true';
        const is_finrisk = localStorage.getItem('is_finrisk') === 'true';
        const is_finbil = localStorage.getItem('is_finbil') === 'true';
        const is_ownfunds = localStorage.getItem('is_ownfunds') === 'true';
        const is_sdvcheck = localStorage.getItem('is_sdvcheck') === 'true';
        const is_pillar3 = localStorage.getItem('is_ownfunds') === 'true';


        if(token === undefined){
            dispatch(logout())
        }
        else{
            const expirationDate = new Date(localStorage.getItem('expirationDate'))
            if (expirationDate <= new Date()){
                // Token scaduto
                dispatch(logout())
            }
            else{
                // Token ancora valido
                dispatch(authSuccess(token, username, is_superuser, is_crcheck, is_finrisk, is_finbil, is_ownfunds, is_sdvcheck, is_pillar3))
                // Imposta il nuovo timeout per il tempo rimanente
                dispatch(checkAuthTimeout((expirationDate.getTime()-new Date().getTime()) / 1000))
            }
        }
    }
}