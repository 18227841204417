import React from 'react';
import { useNavigate } from 'react-router-dom';

const CrNavTabs = ({ activeTab }) => {
  const navigate = useNavigate();

  return (
    <div className="mb-4 pt-3">  {/* Aggiunto pt-3 per padding-top */}
      <nav className="nav nav-tabs">
        <button 
          className={`nav-link ${activeTab === 'monitoring' ? 'active' : ''}`}
          onClick={() => navigate('/cr/monitoring')}
          style={{
            backgroundColor: activeTab === 'monitoring' ? '#23485D' : '#eee',
            color: activeTab === 'monitoring' ? 'white' : '#6c757d',
            fontWeight: activeTab === 'monitoring' ? 'bold' : 'normal',
            borderBottom: activeTab === 'monitoring' ? 'none' : '1px solid #dee2e6',
            borderColor: activeTab === 'monitoring' ? '#18303e' : '#dee2e6',
            borderStyle: 'solid',
            borderWidth: '1px',
            marginBottom: '-1px',
            zIndex: activeTab === 'monitoring' ? '1' : 'auto'
          }}
        >
          CR Guarantee Monitoring
        </button>
        <button 
          className={`nav-link ${activeTab === 'data-load' ? 'active' : ''}`}
          onClick={() => navigate('/cr/data-load')}
          style={{
            backgroundColor: activeTab === 'data-load' ? '#23485D' : '#eee',
            color: activeTab === 'data-load' ? 'white' : '#6c757d',
            fontWeight: activeTab === 'data-load' ? 'bold' : 'normal',
            borderBottom: activeTab === 'data-load' ? 'none' : '1px solid #dee2e6',
            borderColor: activeTab === 'data-load' ? '#18303e' : '#dee2e6',
            borderStyle: 'solid',
            borderWidth: '1px',
            marginBottom: '-1px',
            zIndex: activeTab === 'data-load' ? '1' : 'auto'
          }}
        >
          CR Guarantee Data Load
        </button>
      </nav>
    </div>
  );
};

export default CrNavTabs;