import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';  // Aggiungiamo connect da react-redux

const UserPermissionsEditor = ({ user, show, onHide, onUpdate, token }) => {
    const [permissions, setPermissions] = useState({
        is_superuser: user.is_superuser,
        is_finrisk: user.is_finrisk,
        is_finbil: user.is_finbil,
        is_crcheck: user.is_crcheck,
        is_sdvcheck: user.is_sdvcheck,
        is_pillar3: user.is_pillar3,
        is_ownfunds: user.is_ownfunds,
        abi: user.abi || '',
        finrisk_user: user.finrisk_user || '',
        finrisk_psw: user.finrisk_psw || ''
    });
    const [error, setError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setPermissions(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setError(null);

        try {
            if (!token) {
                throw new Error('Token di autenticazione non trovato');
            }

            // Prepara i dati per l'invio
            const dataToSend = {
                ...permissions,
                abi: permissions.abi === '' ? null : parseInt(permissions.abi, 10)
            };

            const response = await fetch(`/api/users/${user.id}/permissions/`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                },
                credentials: 'include',
                body: JSON.stringify(dataToSend)
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Errore durante l\'aggiornamento dei permessi');
            }

            const data = await response.json();
            onUpdate(data.user);
            onHide();
        } catch (err) {
            setError(err.message);
            console.error('Errore durante l\'aggiornamento:', err);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Modal show={show} onHide={onHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Modifica Permessi: {user.username}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <div className="alert alert-danger">{error}</div>
                )}
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col md={6}>
                            <h6 className="mb-3">Permessi di Sistema</h6>
                            <Form.Group className="mb-3">
                                <Form.Check
                                    type="checkbox"
                                    id="is_superuser"
                                    name="is_superuser"
                                    label="Superuser"
                                    checked={permissions.is_superuser}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check
                                    type="checkbox"
                                    id="is_finrisk"
                                    name="is_finrisk"
                                    label="Finrisk"
                                    checked={permissions.is_finrisk}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check
                                    type="checkbox"
                                    id="is_finbil"
                                    name="is_finbil"
                                    label="Finbil"
                                    checked={permissions.is_finbil}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check
                                    type="checkbox"
                                    id="is_crcheck"
                                    name="is_crcheck"
                                    label="CR Check"
                                    checked={permissions.is_crcheck}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check
                                    type="checkbox"
                                    id="is_sdvcheck"
                                    name="is_sdvcheck"
                                    label="SDV Check"
                                    checked={permissions.is_sdvcheck}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check
                                    type="checkbox"
                                    id="is_pillar3"
                                    name="is_pillar3"
                                    label="Pillar 3"
                                    checked={permissions.is_pillar3}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check
                                    type="checkbox"
                                    id="is_ownfunds"
                                    name="is_ownfunds"
                                    label="OwnFunds"
                                    checked={permissions.is_ownfunds}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <h6 className="mb-3">Configurazione Utente</h6>
                            <Form.Group className="mb-3">
                                <Form.Label>ABI</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="abi"
                                    value={permissions.abi}
                                    onChange={handleChange}
                                    placeholder="Inserisci ABI"
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>FinRisk User</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="finrisk_user"
                                    value={permissions.finrisk_user}
                                    onChange={handleChange}
                                    placeholder="Username FinRisk"
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>FinRisk Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="finrisk_psw"
                                    value={permissions.finrisk_psw}
                                    onChange={handleChange}
                                    placeholder="Password FinRisk"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Annulla
                </Button>
                <Button 
                    variant="primary" 
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                >
                    {isSubmitting ? 'Salvataggio...' : 'Salva Modifiche'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

// Mappiamo lo state Redux alle props
const mapStateToProps = (state) => ({
    token: state.token
});

// Esportiamo il componente connesso a Redux
export default connect(mapStateToProps)(UserPermissionsEditor);