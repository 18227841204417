import React, { useState, useEffect } from 'react';
import { Container, Button, Form, Row, Col, Spinner, Alert, OverlayTrigger, Tooltip  } from 'react-bootstrap';
import { Export } from './Export';
import { connect } from 'react-redux';
import { DTable } from './DTable';

// Funzione di utilità per ottenere l'URL base
const getBaseUrl = () => {
  // Se siamo in sviluppo, usiamo esplicitamente il backend
  if (process.env.NODE_ENV === 'development') {
    return 'http://localhost:8000';
  }
  // In produzione, usa l'origine della pagina
  return window.location.origin;
};

// Funzione per convertire data da YYYY-MM-DD a YYYYMMDD
const formatDateForFilename = (dateString) => {
  return dateString.replace(/-/g, '');
};

// Definizione dei messaggi di help per i tooltip
const helpMessages = {
  'ATTIVO (T)': 'Il file deve essere in formato CSV e il nome deve iniziare con PUMAWAY_ o INDAGINE_ seguito da ABI e data (es: PUMAWAY_xxxxx_yyyymmdd.csv o INDAGINE_xxxxx_yyyymmdd.csv)',
  'ATTIVO (T-1)': 'Il file deve essere in formato CSV e il nome deve iniziare con PUMAWAY_ o INDAGINE_ seguito da ABI e data (es: PUMAWAY_xxxxx_yyyymmdd.csv o INDAGINE_xxxxx_yyyymmdd.csv)',
  'BASE3 (T)': 'Il file può essere in formato CSV o TXT e il nome deve iniziare con BASE3_ seguito da ABI e data (es: BASE3_xxxxx_yyyymmdd.csv)',
  'BASE3 (T-1)': 'Il file può essere in formato CSV o TXT e il nome deve iniziare con BASE3_ seguito da ABI e data (es: BASE3_xxxxx_yyyymmdd.csv)',
  'BASE4 (T)': 'Il file può essere in formato CSV o TXT e il nome deve iniziare con BASE4_ seguito da ABI e data (es: BASE4_xxxxx_yyyymmdd.csv)',
  'BASE4 (T-1)': 'Il file può essere in formato CSV o TXT e il nome deve iniziare con BASE4_ seguito da ABI e data (es: BASE4_xxxxx_yyyymmdd.csv)',
  'COGE (T)': 'Il file deve denominarsi COGE_ABI_yyyymmdd.csv oppure MAGO_ABI_yyyymmdd.xlsx oppure PAND_ABI_yyyymmdd.xlsx)',
  'COGE (T-1)': 'Il file deve denominarsi COGE_ABI_yyyymmdd.csv oppure MAGO_ABI_yyyymmdd.xlsx oppure PAND_ABI_yyyymmdd.xlsx',
  'TITOLI (T)': 'Il file deve denominarsi TITOLI_ABI_yyyymmdd.csv oppure TI066A_ABI_yyyymmdd.csv oppure JOBBER_yyyymmdd.xlsx',
  'TITOLI (T-1)': 'Il file deve denominarsi TITOLI_ABI_yyyymmdd.csv oppure TI066A_ABI_yyyymmdd.csv oppure JOBBER_yyyymmdd.xlsx',
  'PRIF (T)': 'Il file deve essere in formato XBRL e il nome deve iniziare con PRIF_ seguito da ABI e data (es: PRIF_xxxxx_yyyymmdd.xbrl)',
  'PRIF (T-1)': 'Il file deve essere in formato XBRL e il nome deve iniziare con PRIF_ seguito da ABI e data (es: PRIF_xxxxx_yyyymmdd.xbrl)',
  'RACCORDI': 'Il file deve essere in formato Excel (XLS o XLSX) e il nome deve essere RACCORDI_ABI.xlsx o RACCORDI_ABI.xls'
};


// Oggetto che definisce i pattern per ogni tipo di file
const filePatterns = {
  'ATTIVO (T)': {
    prefix: ['PUMAWAY_', 'INDAGINE_'],
    getExpectedName: (abi, date) => [
      `PUMAWAY_${abi}_${formatDateForFilename(date)}`,
      `INDAGINE_${abi}_${formatDateForFilename(date)}`
    ]
  },
  'ATTIVO (T-1)': {
    prefix: ['PUMAWAY_', 'INDAGINE_'],
    getExpectedName: (abi, date) => [
      `PUMAWAY_${abi}_${formatDateForFilename(date)}`,
      `INDAGINE_${abi}_${formatDateForFilename(date)}`
    ]
  },
  'BASE3 (T)': {
    prefix: 'BASE3_',
    getExpectedName: (abi, date) => `BASE3_${abi}_${formatDateForFilename(date)}`
  },
  'BASE3 (T-1)': {
    prefix: 'BASE3_',
    getExpectedName: (abi, date) => `BASE3_${abi}_${formatDateForFilename(date)}`
  },
  'BASE4 (T)': {
    prefix: 'BASE4_',
    getExpectedName: (abi, date) => `BASE4_${abi}_${formatDateForFilename(date)}`
  },
  'BASE4 (T-1)': {
    prefix: 'BASE4_',
    getExpectedName: (abi, date) => `BASE4_${abi}_${formatDateForFilename(date)}`
  },
  'COGE (T)': {
    prefix: ['COGE_', 'MAGO_', 'PAND_'],
    getExpectedName: (abi, date) => [
      `COGE_${abi}_${formatDateForFilename(date)}`,
      `MAGO_${abi}_${formatDateForFilename(date)}`,
      `PAND_${abi}_${formatDateForFilename(date)}`
    ]
  },
  'COGE (T-1)': {
    prefix: ['COGE_', 'MAGO_', 'PAND_'],
    getExpectedName: (abi, date) => [
      `COGE_${abi}_${formatDateForFilename(date)}`,
      `MAGO_${abi}_${formatDateForFilename(date)}`,
      `PAND_${abi}_${formatDateForFilename(date)}`
    ]
  },
  'TITOLI (T)': {
    prefix: ['TITOLI_', 'TI066A_', 'JOBBER_'],
    getExpectedName: (abi, date) => [
      `TITOLI_${abi}_${formatDateForFilename(date)}`,
      `TI066A_${abi}_${formatDateForFilename(date)}`,
      `JOBBER_${formatDateForFilename(date)}`  // Nota: JOBBER_ non richiede ABI
    ]
  },
  'TITOLI (T-1)': {
    prefix: ['TITOLI_', 'TI066A_', 'JOBBER_'],
    getExpectedName: (abi, date) => [
      `TITOLI_${abi}_${formatDateForFilename(date)}`,
      `TI066A_${abi}_${formatDateForFilename(date)}`,
      `JOBBER_${formatDateForFilename(date)}`  // Nota: JOBBER_ non richiede ABI
    ]
  },
  'PRIF (T)': {
      prefix: 'PRIF_',
      getExpectedName: (abi, date) => `PRIF_${abi}_${formatDateForFilename(date)}`
  },
  'PRIF (T-1)': {
      prefix: 'PRIF_',
      getExpectedName: (abi, date) => `PRIF_${abi}_${formatDateForFilename(date)}`
  },
  'RACCORDI': {
    prefix: 'RACCORDI_',
    getExpectedName: (abi) => `RACCORDI_${abi}`  // RACCORDI non ha data
  }
};

/* Variabili di stile per facile personalizzazione */
const styleVariables = {
  // Colori di base
  labelBackground: '#D1D8E8',
  labelBorderOuter: '#18303e',
  labelBorderInner: '#c95e15',
  labelText: '#000000',
  abilabelBackground: '#18303E',
  abiLabelText: '#FFFFFF',       // Colore testo specifico per ABI

  // Colori dei pulsanti Browse
  browseButtonBackground: 'transparent',
  browseButtonBorder: '#18303E',
  browseButtonText: '#18303E',
  
  // Dimensioni comuni
  commonWidth: '180px',
  commonHeight: '30px',
  fontSize: '0.70rem',
  labelFontSize: '0.75rem',     // Dimensione testo etichette
  buttonFontSize: '0.70rem'     // Dimensione testo pulsanti
};

/* Stili delle etichette */
const labelStyles = {
  backgroundColor: styleVariables.labelBackground,
  border: `2.3px solid ${styleVariables.labelBorderOuter}`,
  boxShadow: `inset 0 0 0 1px ${styleVariables.labelBorderInner}`,
  height: styleVariables.commonHeight,
  width: styleVariables.commonWidth,
  color: styleVariables.labelText,
  buttonFontSize: '0.70rem'     // Dimensione testo pulsanti
};

/* Stili delle etichette Abi*/
const AbiStyles = {
  backgroundColor: styleVariables.abilabelBackground,
  border: `2.3px solid ${styleVariables.labelBorderOuter}`,
  boxShadow: `inset 0 0 0 1px ${styleVariables.labelBorderInner}`,
  height: styleVariables.commonHeight,
  width: styleVariables.commonWidth,
  color: styleVariables.abiLabelText,  // Usa il colore specifico per ABI
  buttonFontSize: '0.70rem'     // Dimensione testo pulsanti
};

/* Stili dei pulsanti Browse */
const browseButtonStyles = {
  width: styleVariables.commonWidth,
  height: styleVariables.commonHeight,
  backgroundColor: styleVariables.browseButtonBackground,
  color: styleVariables.browseButtonText,
  borderColor: styleVariables.browseButtonBorder,
  fontSize: styleVariables.buttonFontSize  // Applicato ai pulsanti
};

/* Stili per i form della data */
const dateInputStyles = {
  width: styleVariables.commonWidth,
  height: styleVariables.commonHeight,
  backgroundColor: styleVariables.browseButtonBackground,
  color: styleVariables.browseButtonText,
  borderColor: styleVariables.browseButtonBorder,
  fontSize: styleVariables.buttonFontSize,
  cursor: 'pointer',
  borderWidth: '1px',        // Come i pulsanti outline di Bootstrap
  borderStyle: 'solid',
  padding: '0.375rem',
  borderRadius: '0.375rem',   // Bordo arrotondato come i pulsanti Bootstrap
  textAlign: 'center',  // Questo per centrare il testo
  fontWeight: 'bold'  // Questo per il testo in grassetto
};

// Oggetto che definisce i tipi di files accettati
const acceptedFileTypes = {
  'ATTIVO (T)': '.csv',
  'ATTIVO (T-1)': '.csv',
  'BASE3 (T)': '.csv,.txt',
  'BASE3 (T-1)': '.csv,.txt',
  'BASE4 (T)': '.csv,.txt',
  'BASE4 (T-1)': '.csv,.txt',
  'COGE (T)': '.csv,.xlsx',   
  'COGE (T-1)': '.csv,.xlsx', 
  'TITOLI (T)': '.csv,.xlsx', 
  'TITOLI (T-1)': '.csv,.xlsx',
  'PRIF (T)': '.xbrl',
  'PRIF (T-1)': '.xbrl',
  'RACCORDI': '.xlsx,.xls'
};

const FinBil = (props) => {
  console.log("Props in FinBil:", props);
  console.log("Token from props:", props.token); // Debug log per il token
  /* Stati del componente */
  const [competenza, setCompetenza] = useState('');
  const [competenzaAP, setCompetenzaAP] = useState('');
  const [files, setFiles] = useState({
    'ATTIVO (T)': null,
    'ATTIVO (T-1)': null,
    'BASE3 (T)': null,
    'BASE3 (T-1)': null,
    'BASE4 (T)': null,
    'BASE4 (T-1)': null,
    'COGE (T)': null,
    'COGE (T-1)': null,
    'TITOLI (T)': null,
    'TITOLI (T-1)': null,
    'PRIF (T)': null,
    'PRIF (T-1)': null,
    'RACCORDI': null
  });
  const [resultData, setResultData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [availableAbi, setAvailableAbi] = useState([]);
  const [selectedAbi, setSelectedAbi] = useState(null);
  const [importProgress, setImportProgress] = useState(0);
  const [rulesProgress, setRulesProgress] = useState(0);
  const [currentPhase, setCurrentPhase] = useState('import');

  // Funzione per formattare la data in YYYY-MM-DD
  const formatDate = (dateString) => {
    if (!dateString) return '';
    
    // Rimuove caratteri non numerici
    const cleanDate = dateString.replace(/\D/g, '');
    
    if (cleanDate.length === 8) {
      const year = cleanDate.substring(0, 4);
      const month = cleanDate.substring(4, 6);
      const day = cleanDate.substring(6, 8);
      return `${year}-${month}-${day}`;
    }
    return dateString;
  };

  // Handler per l'input manuale delle date
  const handleDateChange = (e, setter) => {
    let value = e.target.value;
    const formattedDate = formatDate(value);
    setter(formattedDate);
  };

  // Qui va inserito lo useEffect
  useEffect(() => {
    const fetchAbi = async () => {
        if (!props.token) {
            console.log("No token available, skipping API call");
            return;
        }

        try {
            console.log("Fetching ABI with token:", props.token); // Debug log
            
            const response = await fetch('/api/bil-abi/', {
                method: 'GET',
                headers: {
                    'Authorization': `Token ${props.token}`,
                    'Content-Type': 'application/json',
                },
                credentials: 'include'
            });
            
            console.log("API Response status:", response.status);
            
            if (!response.ok) {
                const errorData = await response.text();
                console.error("API Error response:", errorData);
                throw new Error(`Network response was not ok: ${errorData}`);
            }
            
            const data = await response.json();
            console.log("Received ABI data:", data);
            
            setAvailableAbi(data);
            
            if (!props.is_superuser && data.length === 1) {
                setSelectedAbi(data[0].codice_abi);
            }
        } catch (error) {
            console.error("Error fetching ABI:", error);
            setError("Errore nel caricamento degli ABI disponibili");
        }
    };

    fetchAbi();
}, [props.is_superuser, props.user, props.token]);

  /* Gestione del caricamento dei file */
  const handleFileChange = (event, key) => {
    const file = event.target.files[0];
    if (!file) return;

  // Controlli preliminari
  if (!selectedAbi) {
    alert('Selezionare un ABI prima di caricare i file');
    return;
  }

  // Ottieni la data di competenza corretta per questo file
  const competenzaData = key.includes('T-1') ? competenzaAP : competenza;

  if (!competenza && key.includes('(T)')) {
    alert('Inserire la data di competenza (T) prima di caricare i file');
    return;
  }

  if (!competenzaAP && key.includes('(T-1)')) {
    alert('Inserire la data di competenza (T-1) prima di caricare i file');
    return;
  }

    // Verifica estensione del file
    const fileExtension = '.' + file.name.split('.').pop().toLowerCase();
    const acceptedTypes = acceptedFileTypes[key].split(',');
    
    if (!acceptedTypes.includes(fileExtension)) {
      alert(`Per ${key} sono accettati solo i seguenti formati: ${acceptedFileTypes[key]}`);
      return;
    }
  
    // Ottieni il nome del file senza estensione
    const fileName = file.name.split('.')[0];
  
    // Ottieni il pattern corretto per questo tipo di file
    const pattern = filePatterns[key];
    
    // Verifica che il file inizi con il prefisso corretto
    // Il file raccordi non ha data
    if (key === 'RACCORDI') {
      const expectedName = pattern.getExpectedName(selectedAbi);
      if (fileName !== expectedName) {
          alert(`Il nome del file deve essere: ${expectedName}`);
          return;
      }
    // Il file titoli, attivo e coge possono avere prefissi multipli
    } else if (key.startsWith('TITOLI') || key.startsWith('ATTIVO') || key.startsWith('COGE')) {
      const validPrefix = pattern.prefix.some(prefix => fileName.startsWith(prefix));
      if (!validPrefix) {
          alert(`Il nome del file deve iniziare con uno dei seguenti prefissi: ${pattern.prefix.join(' o ')}`);
          return;
      }
      const expectedNames = pattern.getExpectedName(selectedAbi, competenzaData);
      if (!expectedNames.includes(fileName)) {
          alert(`Il nome del file deve essere uno dei seguenti: ${expectedNames.join(' o ')}`);
          return;
      }
    } else {
      if (!fileName.startsWith(pattern.prefix)) {
          alert(`Il nome del file deve iniziare con "${pattern.prefix}"`);
          return;
      }
      // Verifica ABI
      const [filePrefix, fileAbi, fileDate] = fileName.split('_');
      if (fileAbi !== selectedAbi) {
          alert(`Il codice ABI nel nome file (${fileAbi}) non corrisponde all'ABI selezionato (${selectedAbi})`);
          return;
      }
      // Verifica data di competenza
      const expectedDate = formatDateForFilename(competenzaData);
      if (fileDate !== expectedDate) {
          alert(`La data nel nome file (${fileDate}) non corrisponde alla data di competenza (${expectedDate})`);
          return;
      }
    }
  
    // Se tutte le validazioni passano, aggiorna lo stato
    setFiles(prevFiles => ({ ...prevFiles, [key]: file }));
  };

/* Gestione dell'invio dei dati al backend */
  const handleStartQuery = async () => {
    console.log("Token disponibile:", props.token); // Verifica token
    // 1. Prima verifichiamo il token
    if (!props.token) {
      console.error("Token non disponibile");
      setError("Sessione scaduta. Effettua nuovamente il login.");
      return;
  }

      // 2. Poi verifichiamo l'ABI
      if (!selectedAbi) {
        setError("Selezionare un ABI prima di procedere");
        return;
    }
  
    setIsLoading(true);
    setError(null);
  
    const formData = new FormData();
    formData.append('abi', selectedAbi);
    formData.append('competenza', competenza.replace(/-/g, ''));
    formData.append('competenza_ap', competenzaAP.replace(/-/g, ''));
    formData.append('application', 'FINBIL'); // Aggiunta questa riga per invocare viewrulesengine con il parametro application=FINBIL
  
    // Log dei dati base
    console.log("=== FormData Construction ===");
    console.log("ABI:", selectedAbi);
    console.log("Competenza:", competenza);
    console.log("Competenza AP:", competenzaAP);
  
    // Aggiungi i file con i nomi corretti
    const fileMapping = {
      'ATTIVO (T)': { key: 'attivo_file', filename_key: 'attivo_filename' },
      'ATTIVO (T-1)': { key: 'attivo_file_ap', filename_key: 'attivo_filename_ap' },
      'BASE3 (T)': { key: 'base3_file', filename_key: 'base3_filename' },
      'BASE3 (T-1)': { key: 'base3_file_ap', filename_key: 'base3_filename_ap' },
      'BASE4 (T)': { key: 'base4_file', filename_key: 'base4_filename' },
      'BASE4 (T-1)': { key: 'base4_file_ap', filename_key: 'base4_filename_ap' },
      'COGE (T)': { key: 'coge_file', filename_key: 'coge_filename' },
      'COGE (T-1)': { key: 'coge_file_ap', filename_key: 'coge_filename_ap' },
      'TITOLI (T)': { key: 'ti066_file', filename_key: 'ti066_filename' },
      'TITOLI (T-1)': { key: 'ti066_file_ap', filename_key: 'ti066_filename_ap' },
      'PRIF (T)': { key: 'prif_file', filename_key: 'prif_filename' },
      'PRIF (T-1)': { key: 'prif_file_ap', filename_key: 'prif_filename_ap' },
      'RACCORDI': { key: 'raccordi_file', filename_key: 'raccordi_filename' }
    };
  
    Object.entries(files).forEach(([key, file]) => {
      if (file && fileMapping[key]) {
        console.log("🔵 === File Processing Start ===");
        console.log("🔵 Key:", key);
        console.log("🔵 File:", file);
        console.log("🔵 File name:", file.name);
        
        formData.append(fileMapping[key].key, file);
        formData.append(fileMapping[key].filename_key, file.name);
        
        console.log("🟢 === FormData Addition ===");
        console.log("🟢 File field:", fileMapping[key].key);
        console.log("🟢 Filename field:", fileMapping[key].filename_key);
        console.log("🟢 Filename value:", file.name);
      }
    });
    
    // Log finale più dettagliato
    console.log("🔴 === Final FormData Content ===");
    const formDataEntries = Array.from(formData.entries());
    formDataEntries.forEach(([key, value]) => {
      if (value instanceof File) {
        console.log("🔴 [File]", key, "->", value.name);
      } else {
        console.log("🔴 [Data]", key, "->", value);
      }
    });

  // Submit job con token di autorizzazione
  try {
    console.log("Preparazione richiesta con token:", props.token);
    const headers = {
        'Authorization': `Token ${props.token}`
    };
    console.log("Headers preparati:", headers);

    const submitResponse = await fetch('/api/submit-bilancio/', {
        method: 'POST',
        headers: headers,
        credentials: 'include',
        body: formData,
    });

    console.log("Risposta ricevuta:", submitResponse);
    console.log("Status:", submitResponse.status);
    
    if (!submitResponse.ok) {
        const errorText = await submitResponse.text();
        console.error("Errore ricevuto:", errorText);
        throw new Error(`HTTP error! status: ${submitResponse.status}, message: ${errorText}`);
    }

    const { job_id } = await submitResponse.json();
    console.log("Job ID ricevuto:", job_id);

      // Poll per lo stato
      const pollStatus = async () => {
        try {
            console.log(`=== Polling Start for job: ${job_id} ===`); 
            // Effettua la richiesta di stato con autenticazione
            const statusResponse = await fetch(`/api/bilancio-status/${job_id}/`, {
                headers: {
                    'Authorization': `Token ${props.token}`
                },
                credentials: 'include'
            });
            console.log("Raw status response:", statusResponse);
              
            // Ottieni il testo grezzo della risposta
            const rawText = await statusResponse.text();
            console.log("Raw response text:", rawText);
              
            // Parsing del JSON con gestione errori
            let status;
            try {
                status = JSON.parse(rawText);
            } catch (parseError) {
                console.error("Error parsing JSON:", parseError);
                console.error("Invalid JSON content:", rawText);
                throw new Error("Invalid JSON response");
            }
              
            console.log("Parsed status:", status);
      
            // Gestione dei diversi stati dell'elaborazione
            switch (status.status) {
                case 'processing':
                  console.log("=== Processing Status Details ===");
                  console.log("Current Phase:", status.current_phase);
                  console.log("Import Progress:", status.import_progress);
                  console.log("Rules Progress:", status.rules_progress);

                  setCurrentPhase(status.current_phase);
                      
                    // Aggiorna le progress bar in base alla fase
                    if (status.current_phase === 'import') {
                        console.log("Import phase progress:", status.import_progress);  // Progresso della fase di import
                        console.log(`Setting import progress to: ${status.import_progress}%`);

                        // Durante la fase di import, mostra solo la prima progress bar
                        setImportProgress(status.import_progress || 0);
                        setRulesProgress(0);
                        console.log(`Import progress: ${status.import_progress}%`);
                        console.log(`Setting rules progress to: ${status.rules_progress}%`);
                    } else if (status.current_phase === 'rules') {

                      // Durante la fase rules, la prima progress bar è completa
                        // e la seconda mostra l'avanzamento delle regole
                        console.log("Rules phase progress:", status.rules_progress);  // Progresso della fase delle regole
                        setImportProgress(100);
                        setRulesProgress(status.rules_progress || 0);
                        console.log(`Rules progress: ${status.rules_progress}%`);
                    }

                    // Aggiungiamo un log dopo l'aggiornamento dello stato
                    console.log("Current state after update:", {
                      isLoading,
                      currentPhase,
                      importProgress,
                      rulesProgress
                  });

                    // Continua il polling ogni 2 secondi
                    setTimeout(pollStatus, 2000);
                    break;
      
                case 'completed':
                    console.log("Elaborazione completata, full status:", status);
                      
                    // Imposta entrambe le progress bar al 100%
                    setImportProgress(100);
                    setRulesProgress(100);
                      
                    // Gestione dei risultati dell'elaborazione
                    if (status.result) {
                        // Gestione della nota integrativa
                        if (status.result.nota_integrativa) {
                            console.log("Nota integrativa ricevuta");
                            setResultData(status.result.nota_integrativa);
                        } else {
                            console.warn("Nota integrativa non presente nel result");
                        }
      
                        // Gestione del download automatico del file zip
                        if (status.result.download_token) {
                            console.log("Download token ricevuto:", status.result.download_token);
                            const baseUrl = getBaseUrl();
                            const downloadUrl = `${baseUrl}/api/download-bilancio/${status.result.download_token}/`;
                            console.log("Avvio download da:", downloadUrl);
                      
                            // Creazione e click automatico del link di download
                            const link = document.createElement('a');
                            link.href = downloadUrl;
                            link.download = `bilancio_${new Date().toISOString()}.zip`;
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        } else {
                            console.warn("Download token non presente nel result");
                        }
                    } else {
                        console.warn("Result non presente nello status");
                    }
      
                    // Termina il caricamento
                    setIsLoading(false);
                    break;
      
                case 'error':
                    console.error("Errore nell'elaborazione:", status.error);
                    // In caso di errore, resetta le progress bar
                    setImportProgress(0);
                    setRulesProgress(0);
                    setError(status.error);
                    setIsLoading(false);
                    break;
      
                default:
                    console.log(`Stato sconosciuto: ${status.status}`);
                    setTimeout(pollStatus, 2000);
                    break;
            }
        } catch (error) {
            // Gestione degli errori di rete o altri errori imprevisti
            console.error("Errore nel polling:", error);
            console.error("Stack trace:", error.stack);
            setError('Error checking status: ' + error.message);
            setImportProgress(0);
            setRulesProgress(0);
            setIsLoading(false);
        }
    };
      
    // Avvio iniziale del polling
    pollStatus();
      
  } catch (error) {
      console.error("Errore nell'elaborazione:", error);
      setError('Error processing query: ' + error.message);
      setIsLoading(false);
  }
};

/* Componente per la riga di input file 
 * @param {string} key - Chiave che identifica il tipo di file (es: 'ATTIVO (T)', 'BASE3 (T)', etc.)
 * @returns {JSX.Element} - Ritorna un elemento React che rappresenta una riga di input file
 */
const renderFileInput = (key) => (
  // Container principale - flex per allineare gli elementi orizzontalmente
  <div className="d-flex align-items-center mb-3">
    
    {/* Wrapper del Tooltip - OverlayTrigger gestisce quando mostrare/nascondere il tooltip */}
    <OverlayTrigger
      placement="right"           // Posizione del tooltip rispetto all'elemento
      delay={{ show: 250, hide: 400 }}  // Ritardi per mostrare/nascondere il tooltip
      overlay={(props) => (
        // Componente Tooltip con il messaggio di aiuto
        <Tooltip id={`tooltip-${key}`} {...props}>
          {helpMessages[key]}  {/* Messaggio specifico per questo tipo di file */}
        </Tooltip>
      )}
    >
      {/* Etichetta del tipo di file - Questo div viene avvolto dal tooltip */}
      <div 
        className="text-dark p-2 rounded d-flex justify-content-center align-items-center me-3"
        style={{...labelStyles, cursor: 'help'}}  // Aggiunge il cursore help per indicare la presenza del tooltip
      >
        <Form.Label className="fw-bold mb-0">
          {key}  {/* Nome del tipo di file */}
        </Form.Label>
      </div>
    </OverlayTrigger>

    {/* Container per l'input file e il bottone Browse */}
    <div className="d-flex align-items-center">
      {/* Input file nascosto - viene attivato dal bottone Browse */}
      <input
        type="file"
        accept={acceptedFileTypes[key]}  // Tipi di file accettati per questo input
        onChange={(e) => handleFileChange(e, key)}  // Handler per il cambio file
        style={{ display: 'none' }}  // Nasconde l'input nativo
        id={`file-${key}`}  // ID univoco per collegarlo al label
      />
      
      {/* Bottone Browse personalizzato */}
      <Button 
        as="label"  // Renderizza il bottone come label per l'input file
        htmlFor={`file-${key}`}  // Collega il bottone all'input file
        variant="outline-primary"
        className="me-3"
        style={browseButtonStyles}  // Stili personalizzati per il bottone
      >
        Browse {key}
      </Button>

      {/* Testo che mostra il nome del file selezionato */}
      <span className="text-muted" style={{ fontSize: styleVariables.fontSize }}>
        {files[key] ? files[key].name : 'No file selected'}
      </span>
    </div>
  </div>
);

  // Componente per visualizzare le progress bar
  const ProgressBars = () => {
      console.log("=== ProgressBars Render ===");
      console.log("isLoading:", isLoading);
      console.log("currentPhase:", currentPhase);
      console.log("importProgress:", importProgress);
      console.log("rulesProgress:", rulesProgress);
  
      return isLoading && (
          <div className="mt-4">
              {/* Progress bar per l'importazione */}
              <div className="mb-3">
                  <label className="d-block mb-2">
                      Importazione dati: {importProgress}%
                      {console.log("Import Progress Bar rendering:", importProgress)}
                  </label>
                  <div className="progress">
                      <div 
                          className="progress-bar progress-bar-striped progress-bar-animated" 
                          role="progressbar" 
                          style={{
                              width: `${importProgress}%`,
                              transition: "width 0.5s ease-in-out",
                              backgroundColor: "#C95E15"  // Colore della progress bar
                          }}
                          aria-valuenow={importProgress} 
                          aria-valuemin="0" 
                          aria-valuemax="100"
                      />
                  </div>
              </div>
  
              {/* Progress bar per le regole - visibile solo nella fase 'rules' */}
              {currentPhase === 'rules' && (
                  <div className="mb-3">
                      <label className="d-block mb-2">
                          Applicazione regole: {rulesProgress}%
                          {console.log("Rules Progress Bar rendering:", rulesProgress)}
                      </label>
                      <div className="progress">
                          <div 
                              className="progress-bar progress-bar-striped progress-bar-animated" 
                              role="progressbar" 
                              style={{
                                  width: `${rulesProgress}%`,
                                  transition: "width 0.5s ease-in-out",
                                  backgroundColor: "#C95E15"  // Colore della progress bar
                              }}
                              aria-valuenow={rulesProgress} 
                              aria-valuemin="0" 
                              aria-valuemax="100"
                          />
                      </div>
                  </div>
              )}
          </div>
      );
  };
  

  return (
    <Container fluid className="p-4">
      {/* Barra delle azioni in alto */}
      <Row className="mb-4">
        <Col className="d-flex justify-content-end gap-3">
          <Button 
            onClick={handleStartQuery} 
            variant="success"
            disabled={isLoading}
            style={{ width: '150px' }}  // Larghezza dei pulsante di avvio della query
            >
            {isLoading ? <Spinner animation="border" size="sm" /> : 'Start Job'}
          </Button>
          <Export 
            filename='FinBil_Result'
            data={resultData || []}
            disabled={!resultData}
            style={{ width: '150px' }}  // Larghezza del pulsante di export dell'excel
          />
        </Col>
      </Row>

      <Row className="mb-3">
    <Col>
        <div className="d-flex align-items-center">
            <div 
                className="p-2 rounded d-flex justify-content-center align-items-center me-3"
                style={AbiStyles}
            >
                <span className="fw-bold">ABI</span>
            </div>

            {props.is_superuser ? (
                // Menu a tendina per superuser
                <Form.Select 
                    value={selectedAbi || ''}
                    onChange={(e) => setSelectedAbi(e.target.value)}
                    style={dateInputStyles}
                    className="me-3"
                >
                    <option value="">Seleziona ABI</option>
                    {availableAbi.map(abi => (
                        <option key={abi.codice_abi} value={abi.codice_abi}>
                            {abi.codice_abi} - {abi.descrizione_abi}
                        </option>
                    ))}
                </Form.Select>
            ) : (
                // Display per utente normale
                <div 
                    className="p-2 rounded d-flex justify-content-center align-items-center"
                    style={dateInputStyles}
                >
                    {availableAbi.length > 0 
                        ? `${availableAbi[0].codice_abi} - ${availableAbi[0].descrizione_abi}`
                        : 'ABI non impostato'
                    }
                </div>
            )}
        </div>
    </Col>
</Row>

      {/* Contenitore principale dei form */}
      <Row>
        {/* Sezione Competenza (T) */}
        <Col md={6}>
          <div className="mb-4">
            {/* Header con data */}
            <div className="d-flex align-items-center mb-3">
              <div 
                className="text-dark p-2 rounded d-flex justify-content-center align-items-center me-3"
                style={labelStyles}
              >
                <span className="fw-bold">Competenza (T)</span>
              </div>
              <Form.Control
                type="date"
                value={competenza}
                onChange={(e) => handleDateChange(e, setCompetenza)}
                onKeyDown={(e) => {
                  // Permette backspace e delete
                  if (e.key === 'Backspace' || e.key === 'Delete') return;
                  // Permette tab per navigazione
                  if (e.key === 'Tab') return;
                  // Permette navigazione con frecce
                  if (e.key.startsWith('Arrow')) return;
                  // Blocca altri tasti se non sono numeri
                  if (!/[\d-]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                placeholder="YYYY-MM-DD"
                style={dateInputStyles}
              />
            </div>

            {/* Lista dei file per T */}
            {renderFileInput('ATTIVO (T)')}
            {renderFileInput('BASE3 (T)')}
            {renderFileInput('BASE4 (T)')}
            {renderFileInput('COGE (T)')}
            {renderFileInput('TITOLI (T)')}
            {renderFileInput('PRIF (T)')}
            
            {/* RACCORDI sotto la prima colonna */}
            {renderFileInput('RACCORDI')}
          </div>
        </Col>

        {/* Sezione Competenza (T-1) */}
        <Col md={6}>
          <div className="mb-4">
            {/* Header con data */}
            <div className="d-flex align-items-center mb-3">
              <div 
                className="text-dark p-2 rounded d-flex justify-content-center align-items-center me-3"
                style={labelStyles}
              >
                <span className="fw-bold">Competenza (T-1)</span>
              </div>
              <Form.Control
                type="date"
                value={competenzaAP}
                onChange={(e) => handleDateChange(e, setCompetenzaAP)}
                onKeyDown={(e) => {
                  if (e.key === 'Backspace' || e.key === 'Delete') return;
                  if (e.key === 'Tab') return;
                  if (e.key.startsWith('Arrow')) return;
                  if (!/[\d-]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                placeholder="YYYY-MM-DD"
                style={dateInputStyles}
              />
            </div>  

            {/* Lista dei file per T-1 */}
            {renderFileInput('ATTIVO (T-1)')}
            {renderFileInput('BASE3 (T-1)')}
            {renderFileInput('BASE4 (T-1)')}
            {renderFileInput('COGE (T-1)')}
            {renderFileInput('TITOLI (T-1)')}
            {renderFileInput('PRIF (T-1)')}

          </div>
        </Col>
      </Row>

      {/* Messaggi di errore */}
      {error && (
        <Row className="mt-4">
          <Col>
            <Alert variant="danger">{error}</Alert>
          </Col>
        </Row>
      )}
      
      {/* Progress Bars */}
      <ProgressBars />
      
      {resultData && (
        <Row className="mt-4">
          <Col>
            <DTable
              data={resultData}
              filtered={['codice_regola', 'misura_output', 'riga', 'colonna', 'valore']}
              size="sm"
            />
          </Col>
        </Row>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => {
  console.log("Full Redux state:", state); // Debug log dello state completo
  return {
      is_superuser: state.is_superuser === 'false' ? false : true,
      token: state.token,  // Prendi il token dallo state
      user: {
          abi: state.abi,
          username: state.user
      }
  };
};


export default connect(mapStateToProps)(FinBil);