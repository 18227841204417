import React, { useState, useEffect } from 'react';
import { Container, Form, Row, Col, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DTable } from './DTable';

// Stili condivisi
const styleVariables = {
  labelBackground: '#D1D8E8',
  labelBorderOuter: '#18303e',
  labelBorderInner: '#c95e15',
  labelText: '#ffffff',
  commonWidth: '180px',
  commonHeight: '30px',
  fontSize: '0.8rem',
};

const labelStyles = {
  backgroundColor: styleVariables.labelBackground,
  border: `2.3px solid ${styleVariables.labelBorderOuter}`,
  boxShadow: `inset 0 0 0 1px ${styleVariables.labelBorderInner}`,
  height: styleVariables.commonHeight,
  width: styleVariables.commonWidth,
  color: styleVariables.labelText,
  fontSize: styleVariables.fontSize
};

const dateInputStyles = {
  width: styleVariables.commonWidth,
  height: styleVariables.commonHeight,
  fontSize: styleVariables.fontSize,
  borderColor: styleVariables.labelBorderOuter,
  borderWidth: '1px',
  borderStyle: 'solid',
  padding: '0.375rem',
  borderRadius: '0.375rem',
  textAlign: 'center'
};

const CrGuaranteeMonitoring = ({ token, is_superuser, user }) => {
  const navigate = useNavigate();
  const [monitoringData, setMonitoringData] = useState(null);
  const [error, setError] = useState(null);
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [loading, setLoading] = useState(true);

// Funzione per caricare i dati
  const loadMonitoringData = async () => {
    try {
      setLoading(true);
      setError(null);

      // Costruisci query string per i filtri
      const params = new URLSearchParams();
      if (dateFrom) params.append('date_from', dateFrom);
      if (dateTo) params.append('date_to', dateTo);

      const response = await fetch(`/api/cr-monitoring/?${params.toString()}`, {
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();

      if (result.status === 'success') {
        const enhancedData = result.data.map(record => ({
          ...record,
          data_aggiornamento: new Date(record.data_aggiornamento).toLocaleString(),
          // Manteniamo la data originale per la navigazione
          competenza_display: new Date(record.competenza).toLocaleDateString(),
          // La competenza originale serve per la navigazione
          competenza: record.competenza
        }));
        setMonitoringData(enhancedData);
      } else {
        throw new Error(result.error || 'Errore nel caricamento dei dati');
      }
    } catch (error) {
      console.error('Error loading monitoring data:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Gestione del doppio click sulla riga
  const handleRowDoubleClick = (record) => {
    console.log('Double click record:', record);
    // Naviga verso il componente di analisi passando i parametri necessari
    navigate('/cr/analysis', {
      state: {
        abi: record.abi,
        competenza: record.competenza
      }
    });
  };

  // Carica i dati all'avvio e quando cambiano i filtri
  useEffect(() => {
    if (token) {
      loadMonitoringData();
    }
  }, [token, dateFrom, dateTo]);

  return (
    <Container fluid className="p-4">
      {/* Titolo */}
      <Row className="mb-4">
        <Col>
          <p className="text-muted">
            Visualize and monitor CenRis Dataset.
            Double click on the row to see details.
          </p>
        </Col>
      </Row>

      {/* Filtri */}
      <Row className="mb-4">
        <Col md={6}>
          <div className="d-flex align-items-center gap-3">
            <div className="d-flex align-items-center">
              <div 
                className="text-dark p-2 rounded d-flex justify-content-center align-items-center me-3"
                style={labelStyles}
              >
                <span className="fw-bold">DATA DA</span>
              </div>
              <Form.Control
                type="date"
                value={dateFrom}
                onChange={(e) => setDateFrom(e.target.value)}
                style={dateInputStyles}
              />
            </div>
            <div className="d-flex align-items-center">
              <div 
                className="text-dark p-2 rounded d-flex justify-content-center align-items-center me-3"
                style={labelStyles}
              >
                <span className="fw-bold">DATA A</span>
              </div>
              <Form.Control
                type="date"
                value={dateTo}
                onChange={(e) => setDateTo(e.target.value)}
                style={dateInputStyles}
              />
            </div>
          </div>
        </Col>
      </Row>

      {/* Messaggi di errore */}
      {error && (
        <Row className="mb-4">
          <Col>
            <Alert variant="danger">{error}</Alert>
          </Col>
        </Row>
      )}

      {/* Tabella dati */}
      {monitoringData && (
        <Row>
          <Col>
            <DTable
              data={monitoringData}
              filtered={['abi', 'competenza', 'data_aggiornamento', 'numero_records']}
              size="sm"
              onRowDoubleClick={handleRowDoubleClick}
              disableDefaultDoubleClick={true}
            />
          </Col>
        </Row>
      )}

      {/* Loading state */}
      {loading && (
        <Row>
          <Col className="text-center">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Caricamento...</span>
            </div>
          </Col>
        </Row>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  is_superuser: state.is_superuser === 'false' ? false : true,
  token: state.token,
  user: {
    abi: state.abi,
    username: state.user
  }
});

export default connect(mapStateToProps)(CrGuaranteeMonitoring);