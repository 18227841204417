import React, { useState, useEffect } from 'react';
import { Container, Button, Form, Row, Col, Spinner, Alert, OverlayTrigger, Tooltip  } from 'react-bootstrap';
import { Export } from './Export';
import { connect } from 'react-redux';
import { DTable } from './DTable';
import ClientOverview from './ClientOverview';
import { Save } from 'lucide-react';

// Funzione di utilità per ottenere l'URL base dell'applicazione
const getBaseUrl = () => {
  // In sviluppo, usa localhost
  if (process.env.NODE_ENV === 'development') {
    return 'http://localhost:8000';
  }
  // In produzione, usa l'origine della pagina corrente
  return window.location.origin;
};

// Funzione di utilità per formattare le date nel formato richiesto per i nomi dei file
const formatDateForFilename = (dateString) => {
  return dateString.replace(/-/g, '');
};

// Definizione dei messaggi di help per i tooltip
const helpMessages = {
  'CENTRALE RISCHI': 'Il file deve essere in formato ZIP e contenere un PDF. Il nome deve iniziare con CR_ seguito da ABI e data (es: CR_xxxxx_yyyymmdd.zip)',
  'CREDITI DI FIRMA': 'Il file deve essere in formato ZIP e contenere un CSV. Il nome deve iniziare con CFPUMA_ o CASSA_FIRMA_ seguito da ABI e data (es: CFPUMA_xxxxx_yyyymmdd.zip o CASSA_FIRMA_xxxxx_yyyymmdd.zip)'
};

/* Definizione delle variabili di stile per facile personalizzazione */
const styleVariables = {
  // Colori di base per l'interfaccia
  labelBackground: '#D1D8E8',
  labelBorderOuter: '#18303e',
  labelBorderInner: '#c95e15',
  labelText: '#000000',
  abilabelBackground: '#18303E',  // Colore specifico per etichetta ABI
  abiLabelText: '#FFFFFF',        // Colore testo per etichetta ABI
  
  // Stili dei pulsanti Browse
  browseButtonBackground: 'transparent',
  browseButtonBorder: '#18303E',
  browseButtonText: '#18303E',
  
  // Dimensioni comuni per consistenza nell'interfaccia
  commonWidth: '180px',
  commonHeight: '30px',
  fontSize: '0.70rem',
  labelFontSize: '0.75rem',
  buttonFontSize: '0.70rem'
};

/* Stili per le etichette standard */
const labelStyles = {
  backgroundColor: styleVariables.labelBackground,
  border: `2.3px solid ${styleVariables.labelBorderOuter}`,
  boxShadow: `inset 0 0 0 1px ${styleVariables.labelBorderInner}`,
  height: styleVariables.commonHeight,
  width: styleVariables.commonWidth,
  color: styleVariables.labelText,
  fontSize: styleVariables.labelFontSize
};

/* Stili specifici per l'etichetta ABI */
const AbiStyles = {
  backgroundColor: styleVariables.abilabelBackground,
  border: `2.3px solid ${styleVariables.labelBorderOuter}`,
  boxShadow: `inset 0 0 0 1px ${styleVariables.labelBorderInner}`,
  height: styleVariables.commonHeight,
  width: styleVariables.commonWidth,
  color: styleVariables.abiLabelText,
  buttonFontSize: '0.70rem'
};

/* Stili per gli input di tipo data */
const dateInputStyles = {
  width: styleVariables.commonWidth,
  height: styleVariables.commonHeight,
  backgroundColor: styleVariables.browseButtonBackground,
  color: styleVariables.browseButtonText,
  borderColor: styleVariables.browseButtonBorder,
  fontSize: styleVariables.buttonFontSize,
  cursor: 'pointer',
  borderWidth: '1px',
  borderStyle: 'solid',
  padding: '0.375rem',
  borderRadius: '0.375rem',
  textAlign: 'center',
  fontWeight: 'bold'
};

/* Stili per i pulsanti Browse */
const browseButtonStyles = {
  width: styleVariables.commonWidth,
  height: styleVariables.commonHeight,
  backgroundColor: styleVariables.browseButtonBackground,
  color: styleVariables.browseButtonText,
  borderColor: styleVariables.browseButtonBorder,
  fontSize: styleVariables.buttonFontSize
};


// Definizione dei pattern per la validazione dei nomi dei file
const filePatterns = {
  'CENTRALE RISCHI': {
    prefix: 'CR_',
    getExpectedName: (abi, date) => `CR_${abi}_${formatDateForFilename(date)}`
  },
  'CREDITI DI FIRMA': {
    prefix: ['CFPUMA_', 'CASSA_FIRMA_'],
    getExpectedName: (abi, date) => [
      `CFPUMA_${abi}_${formatDateForFilename(date)}`,
      `CASSA_FIRMA_${abi}_${formatDateForFilename(date)}`
    ]
  }
};

// Definizione dei tipi di file accettati per ciascun input
const acceptedFileTypes = {
  'CENTRALE RISCHI': '.zip',
  'CREDITI DI FIRMA': '.zip'
};

// Componente principale CrGuaranteeDataLoad
const CrGuaranteeDataLoad = (props) => {
  // Stati per la gestione dei file
  const [files, setFiles] = useState({
    'CENTRALE RISCHI': null,
    'CREDITI DI FIRMA': null
  });

  // Stati per la gestione dei dati e dello stato dell'applicazione
  const [resultData, setResultData] = useState(null);      // Dati risultanti dall'elaborazione
  const [error, setError] = useState(null);                // Gestione errori
  const [isLoading, setIsLoading] = useState(false);       // Stato di caricamento
  const [headerData, setHeaderData] = useState(null);      // Dati dell'header

  // Stati per la gestione dell'ABI e della competenza
  const [availableAbi, setAvailableAbi] = useState([]);    // Lista degli ABI disponibili
  const [selectedAbi, setSelectedAbi] = useState(null);    // ABI selezionato
  const [competenza, setCompetenza] = useState('');        // Data di competenza

  // Stati per il salvataggio dei dati
  const [isSaving, setIsSaving] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);

  // Stato per il progress tracking
  const [progress, setProgress] = useState(0);
  const [progressMessage, setProgressMessage] = useState('');  // Per messaggi informativi opzionali

  /*Componente progress bar per il tracking dello stato di avanzamento*/
  const ProgressBar = () => {
    return isLoading && (
      <div className="mt-4">
        <div className="mb-3">
          <div className="d-flex justify-content-between mb-2">
            <label className="text-muted">Elaborazione dati: {progress}%</label>
            {progressMessage && <small className="text-muted">{progressMessage}</small>}
          </div>
          <div className="progress" style={{ height: '20px' }}>
            <div 
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              style={{
                width: `${progress}%`,
                backgroundColor: "#C95E15"
              }}
              aria-valuenow={progress}
              aria-valuemin="0"
              aria-valuemax="100"
            />
          </div>
        </div>
      </div>
    );
  };


  // Effetto per il caricamento degli ABI disponibili all'avvio del componente
  useEffect(() => {
    const fetchAbi = async () => {
      // Verifica la presenza del token di autenticazione
      if (!props.token) {
        console.log("No token available, skipping API call");
        return;
      }

      try {
        // Chiamata API per recuperare gli ABI disponibili
        const response = await fetch('/api/bil-abi/', {
          method: 'GET',
          headers: {
            'Authorization': `Token ${props.token}`,
            'Content-Type': 'application/json',
          },
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error(`Network response was not ok: ${await response.text()}`);
        }

        const data = await response.json();
        setAvailableAbi(data);

        // Se l'utente non è superuser e ha un solo ABI disponibile, selezionalo automaticamente
        if (!props.is_superuser && data.length === 1) {
          setSelectedAbi(data[0].codice_abi);
        }
      } catch (error) {
        console.error("Error fetching ABI:", error);
        setError("Errore nel caricamento degli ABI disponibili");
      }
    };

    fetchAbi();
  }, [props.is_superuser, props.token]);

// Funzione per il download dei files
const handleDownload = async (downloadUrl) => {
  try {
    const response = await fetch(downloadUrl, {
      method: 'GET',
      headers: {
        'Authorization': `Token ${props.token}`
      }
    });
    
    if (!response.ok) throw new Error('Download failed');
    
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `cr_result_${new Date().toISOString()}.zip`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Download error:', error);
    setError('Error downloading file: ' + error.message);
  }
};

  // Funzione per la gestione del cambio data
const handleDateChange = (e) => {
  let value = e.target.value;
  const formattedDate = formatDate(value);
  setCompetenza(formattedDate);
};

// Funzione di utilità per la formattazione della data
const formatDate = (dateString) => {
  if (!dateString) return '';
  
  // Rimuove tutti i caratteri non numerici
  const cleanDate = dateString.replace(/\D/g, '');
  
  // Formatta la data in YYYY-MM-DD se ha la lunghezza corretta
  if (cleanDate.length === 8) {
    const year = cleanDate.substring(0, 4);
    const month = cleanDate.substring(4, 6);
    const day = cleanDate.substring(6, 8);
    return `${year}-${month}-${day}`;
  }
  return dateString;
};

// Gestione del caricamento dei file
const handleFileChange = (event, key) => {
  const file = event.target.files[0];
  if (!file) return;

  // Verifica che l'ABI sia stato selezionato
  if (!selectedAbi) {
    alert('Selezionare un ABI prima di caricare i file');
    return;
  }

  // Verifica che la competenza sia stata selezionata
  if (!competenza) {
    alert('Inserire la data di competenza prima di caricare i file');
    return;
  }

  // Verifica dell'estensione del file
  const fileExtension = '.' + file.name.split('.').pop().toLowerCase();
  if (!acceptedFileTypes[key].includes(fileExtension)) {
    alert(`Per ${key} sono accettati solo i seguenti formati: ${acceptedFileTypes[key]}`);
    return;
  }

  // Ottieni il nome del file senza estensione
  const fileName = file.name.split('.')[0];
  const pattern = filePatterns[key];
  
  // Verifica del prefisso del file in base al tipo
  if (key === 'CREDITI DI FIRMA') {
    const validPrefix = pattern.prefix.some(prefix => fileName.startsWith(prefix));
    if (!validPrefix) {
      alert(`Il nome del file deve iniziare con uno dei seguenti prefissi: ${pattern.prefix.join(' o ')}`);
      return;
    }
  } else {
    if (!fileName.startsWith(pattern.prefix)) {
      alert(`Il nome del file deve iniziare con "${pattern.prefix}"`);
      return;
    }
  }

  // Se tutte le validazioni passano, aggiorna lo stato dei file
  setFiles(prevFiles => ({ ...prevFiles, [key]: file }));
};

// Funzione per la gestione dell'avvio dell'elaborazione
const handleStartQuery = async () => {
  // Reset stati
  setProgress(0);
  setProgressMessage('');
  setError(null);
  setIsLoading(true);
  // Verifica la presenza del token
  if (!props.token) {
    console.error("Token non disponibile");
    setError("Sessione scaduta. Effettua nuovamente il login.");
    return;
  }

  // Verifica che siano stati selezionati ABI e competenza
  if (!selectedAbi) {
    setError("Selezionare un ABI prima di procedere");
    return;
  }

  if (!competenza) {
    setError("Selezionare una data di competenza prima di procedere");
    return;
  }

  setIsLoading(true);
  setError(null);

  const formData = new FormData();
  formData.append('abi', selectedAbi);
  formData.append('competenza', competenza.replace(/-/g, ''));

  // Aggiunge i file al FormData
  const fileMapping = {
    'centrale_rischi': files['CENTRALE RISCHI'],
    'crediti_di_firma': files['CREDITI DI FIRMA']
  };
  Object.entries(fileMapping).forEach(([key, file]) => {
    if (file) {
      formData.append(key, file);
    }
  });

  try {
    // Invio della richiesta al server
    const submitResponse = await fetch('/api/cr-pdfelab/', {
      method: 'POST',
      headers: {
        'Authorization': `Token ${props.token}`
      },
      credentials: 'include',
      body: formData,
    });

    if (!submitResponse.ok) {
      throw new Error(`HTTP error! status: ${submitResponse.status}`);
    }

    const { job_id } = await submitResponse.json();

    // Funzione per il polling dello stato dell'elaborazione
    const pollStatus = async () => {
      try {
        const statusResponse = await fetch(`/api/cr-pdfelab-status/${job_id}/`, {
          headers: {
            'Authorization': `Token ${props.token}`
          },
          credentials: 'include'
        });
    
        const status = await statusResponse.json();
    
        // Aggiorna il progresso
        if (status.progress !== undefined) {
          setProgress(status.progress);
        }
        if (status.message) {
          setProgressMessage(status.message);
        }
    
        switch (status.status) {
          case 'processing':
            setTimeout(pollStatus, 2000);
            break;
    
          case 'completed':
            setProgress(100);
            setProgressMessage('Elaborazione completata');
            
            if (status.result) {
              if (status.result.data) {
                setResultData(status.result.data);
              }
              
              if (status.result.download_token) {
                const baseUrl = getBaseUrl();
                const downloadUrl = `${baseUrl}/api/download-cr/${status.result.download_token}/`;
                handleDownload(downloadUrl);
              }
            }
            setIsLoading(false);
            break;
    
          case 'error':
            setError(status.error);
            setIsLoading(false);
            break;
        }
      } catch (error) {
        setError('Error checking status: ' + error.message);
        setIsLoading(false);
      }
    };

    // Avvia il polling
    pollStatus();

  } catch (error) {
    setError('Error processing query: ' + error.message);
    setIsLoading(false);
  }
};

// Funzione per il salvataggio dei dati
const handleSaveData = async () => {
  if (!props.token || !selectedAbi || !competenza || !resultData) {
    setError("Dati mancanti per il salvataggio");
    return;
  }

  setIsSaving(true);
  setError(null);
  setSaveSuccess(false);

  // Log dei dati che stiamo per inviare
  console.log("Dati per il salvataggio:", {
    token: props.token,
    abi: selectedAbi,
    competenza: competenza,
    dataLength: resultData.length
  });

  try {
    const response = await fetch('/api/cr-save-data/', {
      method: 'POST',
      headers: {
        'Authorization': `Token ${props.token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        abi: selectedAbi,
        competenza: competenza,
        data: resultData
      }),
      credentials: 'include'  // Aggiungiamo questa opzione
    });

    // Log della risposta
    console.log("Status risposta:", response.status);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    setSaveSuccess(true);
    // Mostra una notifica di successo temporanea
    setTimeout(() => setSaveSuccess(false), 5000);

  } catch (error) {
    setError('Error saving data: ' + error.message);
  } finally {
    setIsSaving(false);
  }
};

// Componente per il rendering di una riga di input file
const renderFileInput = (key) => (
  // Container principale - flex per allineare gli elementi orizzontalmente
  <div className="d-flex align-items-center mb-3">
    
    {/* Wrapper del Tooltip - OverlayTrigger gestisce quando mostrare/nascondere il tooltip */}
    <OverlayTrigger
      placement="right"           // Posizione del tooltip rispetto all'elemento
      delay={{ show: 250, hide: 400 }}  // Ritardi per mostrare/nascondere il tooltip
      overlay={(props) => (
        // Componente Tooltip con il messaggio di aiuto
        <Tooltip id={`tooltip-${key}`} {...props}>
          {helpMessages[key]}  {/* Messaggio specifico per questo tipo di file */}
        </Tooltip>
      )}
    >
      {/* Etichetta del tipo di file - Questo div viene avvolto dal tooltip */}
      <div 
        className="text-dark p-2 rounded d-flex justify-content-center align-items-center me-3"
        style={{...labelStyles, cursor: 'help'}}  // Aggiunge il cursore help per indicare la presenza del tooltip
      >
        <Form.Label className="fw-bold mb-0">
          {key}  {/* Nome del tipo di file */}
        </Form.Label>
      </div>
    </OverlayTrigger>

    {/* Input file nascosto e bottone Browse */}
    <div className="d-flex align-items-center">
      <input
        type="file"
        accept={acceptedFileTypes[key]}
        onChange={(e) => handleFileChange(e, key)}
        style={{ display: 'none' }}
        id={`file-${key}`}
      />
      <Button 
        as="label" 
        htmlFor={`file-${key}`}
        variant="outline-primary"
        className="me-3"
        style={browseButtonStyles}
      >
        Browse {key}
      </Button>
      <span className="text-muted" style={{ fontSize: styleVariables.fontSize }}>
        {files[key] ? files[key].name : 'No file selected'}
      </span>
    </div>
  </div>
);

// Rendering del componente principale
return (
  <Container fluid className="p-4">
    {/* Barra delle azioni */}
    <Row className="mb-4">
      <Col className="d-flex justify-content-end gap-3">
        <Button 
          onClick={handleStartQuery} 
          variant="success"
          disabled={isLoading || !selectedAbi || !competenza}
          style={{ width: '150px' }}
        >
          {isLoading ? <Spinner animation="border" size="sm" /> : 'Start Job'}
        </Button>

    {/* Pulsante Save Data */}
    {resultData && !isLoading && (
        <Button 
          onClick={handleSaveData}
          variant="primary"
          disabled={isSaving || !resultData}
          className="d-flex align-items-center justify-content-center gap-2"
          style={{ width: '150px' }}
        >
          {isSaving ? (
            <Spinner animation="border" size="sm" />
          ) : (
            <>
              <Save size={18} />
              Save Data
            </>
          )}
        </Button>
      )}

        <Export 
          filename='CrCheck_Result'
          data={resultData || []}
          disabled={!resultData}
          style={{ width: '150px' }}
        />
      </Col>
    </Row>

    {/* Selezione ABI */}
    <Row className="mb-3">
      <Col>
        <div className="d-flex align-items-center">
          <div 
            className="p-2 rounded d-flex justify-content-center align-items-center me-3"
            style={AbiStyles}
          >
            <span className="fw-bold">ABI</span>
          </div>

          {props.is_superuser ? (
            <Form.Select 
              value={selectedAbi || ''}
              onChange={(e) => setSelectedAbi(e.target.value)}
              style={dateInputStyles}
              className="me-3"
            >
              <option value="">Seleziona ABI</option>
              {availableAbi.map(abi => (
                <option key={abi.codice_abi} value={abi.codice_abi}>
                  {abi.codice_abi} - {abi.descrizione_abi}
                </option>
              ))}
            </Form.Select>
          ) : (
            <div 
              className="p-2 rounded d-flex justify-content-center align-items-center"
              style={dateInputStyles}
            >
              {availableAbi.length > 0 
                ? `${availableAbi[0].codice_abi} - ${availableAbi[0].descrizione_abi}`
                : 'ABI non impostato'
              }
            </div>
          )}
        </div>
      </Col>
    </Row>

    {/* Selezione Competenza */}
    <Row className="mb-3">
      <Col>
        <div className="d-flex align-items-center">
          <div 
            className="text-dark p-2 rounded d-flex justify-content-center align-items-center me-3"
            style={labelStyles}
          >
            <span className="fw-bold">COMPETENZA</span>
          </div>
          <Form.Control
            type="date"
            value={competenza}
            onChange={handleDateChange}
            onKeyDown={(e) => {
              if (e.key === 'Backspace' || e.key === 'Delete') return;
              if (e.key === 'Tab') return;
              if (e.key.startsWith('Arrow')) return;
              if (!/[\d-]/.test(e.key)) {
                e.preventDefault();
              }
            }}
            placeholder="YYYY-MM-DD"
            style={dateInputStyles}
          />
        </div>
      </Col>
    </Row>

    {/* Input file */}
    <Row>
      <Col>
        {renderFileInput('CENTRALE RISCHI')}
        {renderFileInput('CREDITI DI FIRMA')}
      </Col>
    </Row>

    {/* Messaggi di errore */}
    {error && (
      <Row className="mt-4">
        <Col>
          <Alert variant="danger">{error}</Alert>
        </Col>
      </Row>
    )}

    {/* Progress Bar */}
    <ProgressBar />

  {/* Messaggi di successo/errore */}
  {saveSuccess && (
    <Row className="mt-4">
      <Col>
        <Alert variant="success">
          Dati salvati con successo!
        </Alert>
      </Col>
    </Row>
  )}

{error && (
  <Row className="mt-4">
    <Col>
      <Alert variant="danger">{error}</Alert>
    </Col>
  </Row>
)}
    {/* Tabella risultati */}
    {resultData && (
      <Row className="mt-4">
        <Col>
          <div>Dati ricevuti: {resultData.length}</div>
          <DTable
            data={resultData || []}
            filtered={[
              'CODICE_CR_INTERNO',
              'Codice Censito', 
              'NDG',
              'INTESTAZIONE',
              'Descrizione_Garantito',
              'GDR_DESCR'
            ]}
            size="sm"
            detailComponent={ClientOverview}
          />
        </Col>
      </Row>
    )}
  </Container>
);
};

// Mappatura dello state Redux alle props del componente
const mapStateToProps = (state) => ({
is_superuser: state.is_superuser === 'false' ? false : true,
token: state.token,
user: {
  abi: state.abi,
  username: state.user
}
});

// Esportazione del componente connesso a Redux
export default connect(mapStateToProps)(CrGuaranteeDataLoad);