import * as actions from '../actions/actionTypes'
import { updateObject } from '../utility'

const initialState = {
    token:null,
    error:null,
    user: null,
    is_superuser: false,  // Cambiato da null a false
    pagesComp :[],
    pagesHome :[],
    is_crcheck: false,    // Cambiato da null a false
    is_finrisk: false,    // Cambiato da null a false
    is_finbil: false,      // Cambiato da null a false
    is_ownfunds: false,      // Cambiato da null a false
    is_sdvcheck: false,      // Cambiato da null a false
    is_pillar3: false      // Cambiato da null a false

}

const authStart = (state,action) =>{
    return updateObject(state,{
        error :null,
        user :action.user
    })
}

const authSuccess = (state, action) => {
    console.log('Auth Success - Before:', { state, action });
    
    const newState = {
        ...state,
        token: action.token,
        error: null,
        user: action.user,
        is_superuser: action.is_superuser,
        is_crcheck: action.is_crcheck,
        is_finrisk: action.is_finrisk,
        is_finbil: action.is_finbil,
        is_ownfunds: action.is_ownfunds,
        is_sdvcheck: action.is_sdvcheck,
        is_pillar3: action.is_pillar3

    };
    
    console.log('Auth Success - After:', newState);
    return newState;
};

const authFail = (state, action) =>{
    return updateObject(state,{
        error :action.error
    })
}

const authLogout = (state, action) => {
    return updateObject(state, {
        token: null,
        user: null,
        is_superuser: false,
        is_crcheck: false,
        is_finrisk: false,
        is_finbil: false,
        is_ownfunds: false,
        is_sdvcheck: false,
        is_pillar3: false
    })
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.AUTH_START: return authStart(state, action)
        case actions.AUTH_SUCCESS: return authSuccess(state, action)
        case actions.AUTH_FAIL: return authFail(state, action)
        case actions.AUTH_LOGOUT: return authLogout(state, action)
        default: return state
    }
}

export default reducer