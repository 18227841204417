import React, { useState, useEffect } from 'react'
import { Container, Card, Form, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import * as actions from '../store/actions/auth'
import MFASetup from './MFASetup';
import MFAVerify from './MFAVerify';
import logo from '../assets/logos/FullLogo_Transparent_NoBuffer.png';

export default function Login() {
    const [userName, setUserName] = useState(null)
    const [userPwd, setUserPwd] = useState(null)
    const error = useSelector((state) => state.error)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const cardBackgroundColor = 'linear-gradient(90deg, rgba(232,239,242,1) 0%, rgba(227,233,237,1) 39%, rgba(215,221,224,1) 100%)';  // Gradiente
    const activeOuterBorderColor = '#18303e';  // Blu scuro
    const activeInnerBorderColor = '#c95e15';  // Arancione
    // stati per gestione MFA
    const [requireMFA, setRequireMFA] = useState(false);
    const [requireMFASetup, setRequireMFASetup] = useState(false);

    // Gestione completamento MFA
    const handleMFAComplete = () => {
        navigate('/home');
    }

    const login = (e) => {
        e.preventDefault();
        if (userName !== null && userPwd !== null) {
            dispatch(actions.authLogin(userName, userPwd, navigate))
                .then(response => {
                    if (response?.require_2fa) {
                        setRequireMFA(true);  // Aggiunto questo
                        localStorage.setItem('temp_token', response.temp_token);
                    } else if (response?.require_mfa_setup) {
                        setRequireMFASetup(true);
                        localStorage.setItem('temp_token', response.temp_token);
                    }
                })
                .catch(console.error);
        } else {
            alert('Mandatory field empty');
        }
    };

    useEffect(() => {
        // Aggiungo la classe 'login-page' al body quando il componente viene montato
        document.body.classList.add('login-page');

        // Rimuovo la classe quando il componente viene smontato
        return () => {
            document.body.classList.remove('login-page');
        };
    }, []);

    return (
        <Container className='w-50 mt-5'>
            {requireMFASetup ? (
                <MFASetup onSetupComplete={handleMFAComplete} />
            ) : requireMFA ? (
                <MFAVerify onVerificationComplete={handleMFAComplete} />
            ) : (
                <>
                    <Card className=''
                        style={{
                            border: `2.8px solid ${activeOuterBorderColor}`,
                            background: cardBackgroundColor,
                        }}>
     
                        {/* Logo sopra l'header della card */}
                        <img 
                            src={logo}
                            alt="Company Logo" 
                            style={{ width: '200px', margin: '10px auto', display: 'block' }}
                        />
     
                        <Card.Header style={{ backgroundColor: '#18303e', color: 'white'}}>
                            <h2 className='mb-3'>
                                PumaTools Login
                            </h2>
                        </Card.Header>
                        <Card.Body>
                            <Form className='' onSubmit={(e) => login(e)} >
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label style={{ fontWeight: 'bold' }}>UserName</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter username"
                                        onChange={(e) => setUserName(e.target.value)}
                                        style={{ backgroundColor: '#fbfcfe' }} 
                                    />
                                </Form.Group>
     
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label style={{ fontWeight: 'bold' }}>Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Password"
                                        onChange={(e) => setUserPwd(e.target.value)}
                                        style={{ backgroundColor: '#fbfcfe' }} 
                                    />
                                </Form.Group>
                                
                                {error ? <p className='text-danger'>
                                    {JSON.stringify(error)}
                                </p> : null}
     
                                <Button
                                    variant="primary"
                                    type="submit"
                                    className="mb-3"
                                    style={{ backgroundColor: '#15253f', borderColor: '#15253f' }}
                                >
                                    Submit
                                </Button>
                            </Form>
                            <Link to="/password-reset" style={{ color: '#18303e' }}>Forgot your password?</Link>
                        </Card.Body>
                        <Card.Footer style={{ backgroundColor: '#c95e15', borderTop: '3px solid #18303e' }}>
                            <Button
                                variant="link"
                                type="submit"
                                onClick={() => navigate('/signup')}
                                style={{ color: 'white', fontWeight: 'bold' }}
                            >
                                Register and activate now your account
                            </Button>
                        </Card.Footer>
                    </Card>
     
                    {/* Messaggio per la licenza di prova */}
                    <div className="mt-4 text-center">
                        <p>Do you want to try PumaTools? Ask for a free trial license at: <a href="mailto:pumatools@ares-bi.com">pumatools@ares-bi.com</a></p>
                    </div>
     
                    {/* Video YouTube commentato */}
                    {/*
                    <div className="mt-5">
                        <h3 className="mb-3">Guida all'uso di PumaTool</h3>
                        <iframe
                            width="560"
                            height="315"
                            src="https://www.youtube.com/embed/XkDSQq0fwfU?si=e23ifkU0zNtu_9fw"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                            allowFullScreen
                        ></iframe>
                    </div>
                    */}
                </>
            )}
        </Container>
     );
    }