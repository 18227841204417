import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { ChevronDown, ChevronUp, Trash2, Edit } from 'lucide-react';
import { Alert, Spinner } from 'react-bootstrap';
import CRNotesForm from './CRNotesForm';

// Componente per la singola nota
// Passa le informazioni tramite props a CRNotesForm per gestire la modifica della nota
const NoteCard = ({ 
  note, 
  onEdit, 
  onDelete, 
  isDeleting,
  token,
  onDataUpdate,
  clientData  // Attraverso questa prop passiamo i dati del cliente a CRNotesForm
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);

  // Formatta la data in formato leggibile
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  // Gestisce la richiesta di eliminazione nota
  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this note?')) {
      await onDelete(note.id);
    }
  };

  // Gestisce la richiesta di modifica nota
  const handleEdit = () => {
    setShowEditForm(true);
  };

  return (
    <div className="bg-white rounded-lg shadow-sm mb-4 overflow-hidden border border-gray-200">
      {/* Note Header */}
      <div className="p-4 bg-gray-50 border-b border-gray-200">
        <div className="flex justify-between items-start">
          <div className="flex-1">
            <h3 className="text-lg font-semibold text-gray-800 mb-1">
              {note.title_text}
            </h3>
            <div className="text-sm text-gray-600">
              <span className="font-medium">Created by:</span> {note.created_by} | 
              <span className="font-medium ml-2">Date:</span> {formatDate(note.reference_date)}
            </div>
          </div>
          <div className="flex items-center gap-2 ml-4">
            <button
              onClick={handleEdit}
              disabled={isDeleting}
              className="p-2 bg-green-400 hover:bg-green-600 text-white rounded-full transition-colors"
              title="Edit note"
            >
              <Edit size={18} />
            </button>
            <button
              onClick={handleDelete}
              disabled={isDeleting}
              className="p-2 bg-red-400 hover:bg-red-600 text-white rounded-full transition-colors"
              title="Delete note"
            >
              {isDeleting ? (
                <Spinner animation="border" size="sm" />
              ) : (
                <Trash2 size={18} />
              )}
            </button>
            <button
              onClick={() => setIsExpanded(!isExpanded)}
              className="p-1 hover:bg-gray-200 rounded-full transition-colors"
              title={isExpanded ? "Collapse" : "Expand"}
            >
              {isExpanded ? (
                <ChevronUp size={20} className="text-gray-600" />
              ) : (
                <ChevronDown size={20} className="text-gray-600" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Note Content */}
      {isExpanded && (
        <div className="p-4 bg-white">
          <p className="text-gray-700 whitespace-pre-wrap">{note.note_text}</p>
          <div className="mt-4 text-sm text-gray-500">
            <div>Last updated: {formatDate(note.updated_at)}</div>
            {note.updated_by !== note.created_by && (
              <div>Updated by: {note.updated_by}</div>
            )}
          </div>
        </div>
      )}

      {/* Edit Form Modal */}
      {showEditForm && (
        <CRNotesForm
          show={showEditForm}
          onHide={() => setShowEditForm(false)}
          initialData={{
            note_title_text: note.title_text,
            note_text: note.note_text,
          }}
          clientData={clientData}  // Passiamo client data a CRNotesForm
          onSave={onEdit}
          token={token}
          onDataUpdate={onDataUpdate}
        />
      )}
    </div>
  );
};

// Componente principale per la sezione note
// Utilizziamo forwardRef per esporre funzioni al componente padre
const NotesSection = forwardRef(({ 
  clientData,
  token,
  onDataUpdate
}, ref) => {
  const [notes, setNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deletingNoteId, setDeletingNoteId] = useState(null);

  // Funzione per recuperare le note dal server
  const fetchNotes = async () => {
    try {
      setIsLoading(true);
      setError(null);

      const params = new URLSearchParams({
        abi: clientData.ABI,
        codice_cr_interno: clientData.CODICE_CR_INTERNO
      });

      const response = await fetch(`/api/cr-notes/?${params}`, {
        headers: {
          'Authorization': `Token ${token}`,
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch notes');
      }

      const data = await response.json();
      if (data.status === 'success') {
        setNotes(data.data.sort((a, b) => 
          new Date(b.created_at) - new Date(a.created_at)
        ));
      } else {
        throw new Error(data.error || 'Failed to fetch notes');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Espone fetchNotes al componente padre tramite ref
  useImperativeHandle(ref, () => ({
    fetchNotes
  }));

  // Carica le note all'avvio e quando cambiano i dati del cliente
  useEffect(() => {
    fetchNotes();
  }, [clientData.CODICE_CR_INTERNO, clientData.ABI]);

  // Gestisce la modifica di una nota
  const handleEdit = async (noteId, updatedData) => {
    try {
      const response = await fetch(`/api/cr-notes/${noteId}/`, {
        method: 'PUT',
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData)
      });

      if (!response.ok) {
        throw new Error('Failed to update note');
      }

      await fetchNotes();
      if (onDataUpdate) {
        await onDataUpdate();
      }
    } catch (err) {
      setError(err.message);
    }
  };

  // Gestisce l'eliminazione di una nota
  const handleDelete = async (noteId) => {
    try {
      setDeletingNoteId(noteId);
      
      const response = await fetch(`/api/cr-notes/${noteId}/`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Token ${token}`,
        }
      });

      if (!response.ok) {
        throw new Error('Failed to delete note');
      }

      await fetchNotes();
      if (onDataUpdate) {
        await onDataUpdate();
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setDeletingNoteId(null);
    }
  };

  // Render del componente
  if (isLoading) {
    return (
      <div className="flex justify-center items-center p-8">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading notes...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="danger" className="mb-4 flex items-center gap-2">
        <span>Error loading notes: {error}</span>
      </Alert>
    );
  }

  return (
    <div>
      <div className="bg-[#C95E15] text-white p-2 rounded-t-lg">
        <h2 className="text-lg font-semibold">Notes</h2>
      </div>
      <div className="p-4">
        {notes.length === 0 ? (
          <div className="text-gray-500 text-center p-4 bg-gray-50 rounded-lg">
            No notes available for this client.
          </div>
        ) : (
          notes.map(note => (
            <NoteCard
              key={note.id}
              note={note}
              onEdit={(updatedData) => handleEdit(note.id, updatedData)}
              onDelete={() => handleDelete(note.id)}
              isDeleting={deletingNoteId === note.id}
              token={token}
              onDataUpdate={onDataUpdate}
              clientData={clientData}  // Nel render passiamo clientData a NoteCard
            />
          ))
        )}
      </div>
    </div>
  );
});

export default NotesSection;