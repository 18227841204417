import React from 'react';

const Footer = () => {
  return (
    <footer 
      style={{
        backgroundColor: '#18303e',
        borderTop: '2px solid #c95e15',
        position: 'fixed',
        bottom: 0,
        width: '100%',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 20px',
        zIndex: 1000
      }}
    >
      <span style={{ 
        color: 'white',
        fontSize: '0.85rem',
        fontWeight: 'light'
      }}>
        2024 - Engineered by A.Re.S.-BI
      </span>
    </footer>
  );
};

export default Footer;