import React, { useState, useEffect } from 'react';
import { Container, Button, Form, Row, Col, Spinner, Alert, ProgressBar, Modal } from 'react-bootstrap';
import { Export } from './Export';
import { connect } from 'react-redux';
import { DTable } from './DTable';
import UtilitiesNavTabs from './UtilitiesNavTabs';

/* Riutilizziamo gli stessi stili di FinBil */
const styleVariables = {
  labelBackground: '#D1D8E8',
  labelBorderOuter: '#18303e',
  labelBorderInner: '#c95e15',
  labelText: '#000000',
  browseButtonBackground: 'transparent',
  browseButtonBorder: '#18303E',
  browseButtonText: '#18303E',
  commonWidth: '180px',
  commonHeight: '30px',
  fontSize: '0.70rem',
  buttonFontSize: '0.70rem'
};

const labelStyles = {
  backgroundColor: styleVariables.labelBackground,
  border: `2.3px solid ${styleVariables.labelBorderOuter}`,
  boxShadow: `inset 0 0 0 1.8px ${styleVariables.labelBorderInner}`,
  height: styleVariables.commonHeight,
  width: styleVariables.commonWidth,
  color: styleVariables.labelText,
  buttonFontSize: '0.70rem'
};

const browseButtonStyles = {
  width: styleVariables.commonWidth,
  height: styleVariables.commonHeight,
  backgroundColor: styleVariables.browseButtonBackground,
  color: styleVariables.browseButtonText,
  borderColor: styleVariables.browseButtonBorder,
  fontSize: styleVariables.buttonFontSize
};

const ImportFinBilRaccordiRules = (props) => {
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [resultData, setResultData] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [jobId, setJobId] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  // Chiamata API per vedere la tabella delle regole dei raccordi
  useEffect(() => {
    const fetchRules = async () => {
      try {
        const response = await fetch('/api/get-bil-raccordi-rules/', {
          headers: {
            'Authorization': `Token ${props.token}`
          },
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setResultData(data.rules);
      } catch (error) {
        setError('Errore nel caricamento delle regole: ' + error.message);
      }
    };

    fetchRules();
  }, [props.token]);

  // Gestione del file
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) return;

    // Verifica estensione
    const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
    if (!['xlsx', 'xls'].includes(fileExtension)) {
      setError('Il file deve essere in formato Excel (.xlsx o .xls)');
      return;
    }

    // Verifica nome file
    if (selectedFile.name.split('.')[0] !== 'REGOLE_RACCORDI') {
      setError('Il nome del file deve essere REGOLE_RACCORDI');
      return;
    }

    setFile(selectedFile);
    setError(null);
  };

  // Gestione dell'invio
  const handleStartQuery = async () => {
    if (!file) {
      setError('Selezionare un file prima di procedere');
      return;
    }

    if (!props.token) {
      setError('Sessione scaduta. Effettua nuovamente il login.');
      return;
    }

    setIsLoading(true);
    setError(null);
    setUploadProgress(0);

    const formData = new FormData();
    formData.append('rules_file', file);

    try {
      const headers = {
        'Authorization': `Token ${props.token}`
      };

      const response = await fetch('/api/upload-finbil-raccordi-rules/', {
        method: 'POST',
        headers: headers,
        credentials: 'include',
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setJobId(data.job_id);
      startPolling(data.job_id);
    } catch (error) {
      console.error('Error:', error);
      setError('Errore durante l\'importazione: ' + error.message);
      setIsLoading(false);
    }
  };

  // Polling del progresso
  const startPolling = (jobId) => {
    const pollInterval = setInterval(async () => {
      try {
        const response = await fetch(`/api/finbil-raccordi-rules-status/${jobId}/`, {
          headers: {
            'Authorization': `Token ${props.token}`
          },
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        
        if (data.status === 'completed') {
          clearInterval(pollInterval);
          setIsLoading(false);
          setShowSuccessModal(true);
          // Ricarica i dati della tabella
          const response = await fetch('/api/get-bil-raccordi-rules/', {
            headers: {
              'Authorization': `Token ${props.token}`
            },
            credentials: 'include'
          });
          const rulesData = await response.json();
          setResultData(rulesData.rules);
        } else if (data.status === 'error') {
          clearInterval(pollInterval);
          setError(data.error);
          setIsLoading(false);
        } else {
          // Aggiorna il progresso
          setUploadProgress(Math.round((data.processed_rows / data.total_rows) * 100));
        }
      } catch (error) {
        clearInterval(pollInterval);
        setError('Errore durante il monitoraggio: ' + error.message);
        setIsLoading(false);
      }
    }, 1000);
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
  };

  return (
    <Container fluid className="p-4">
      <UtilitiesNavTabs />
      {/* Barra delle azioni */}
      <Row className="mb-4">
        <Col className="d-flex justify-content-end gap-3">
          <Button 
            onClick={handleStartQuery} 
            variant="success"
            disabled={isLoading}
            style={{ width: '150px' }}
          >
            {isLoading ? <Spinner animation="border" size="sm" /> : 'Upload Rules'}
          </Button>
          <Export 
            filename='FinBil_Raccordi_Rules'
            data={resultData || []}
            disabled={!resultData}
            style={{ width: '150px' }}
          />
        </Col>
      </Row>

      {/* File input */}
      <Row className="mb-4">
        <Col>
          <div className="d-flex align-items-center">
            <div 
              className="text-dark p-2 rounded d-flex justify-content-center align-items-center me-3"
              style={labelStyles}
            >
              <Form.Label className="fw-bold mb-0">
                REGOLE_RACCORDI
              </Form.Label>
            </div>
            <div className="d-flex align-items-center">
              <input
                type="file"
                accept=".xlsx,.xls"
                onChange={handleFileChange}
                style={{ display: 'none' }}
                id="rules-file"
              />
              <Button 
                as="label" 
                htmlFor="rules-file"
                variant="outline-primary"
                className="me-3"
                style={browseButtonStyles}
              >
                Browse
              </Button>
              <span className="text-muted" style={{ fontSize: styleVariables.fontSize }}>
                {file ? file.name : 'No file selected'}
              </span>
            </div>
          </div>
        </Col>
      </Row>

      {/* Progress Bar */}
      {isLoading && (
        <Row className="mt-3">
          <Col>
            <div className="d-flex align-items-center">
              <div className="w-100">
                <ProgressBar 
                  now={uploadProgress} 
                  label={`${uploadProgress}%`} 
                  animated
                />
              </div>
            </div>
          </Col>
        </Row>
      )}

      {/* Messaggi di errore */}
      {error && (
        <Row className="mt-4">
          <Col>
            <Alert variant="danger">{error}</Alert>
          </Col>
        </Row>
      )}

      {/* Tabella risultati */}
      {resultData && (
        <Row className="mt-4">
          <Col>
            <DTable
              data={resultData}
              filtered={['codice_regola', 'filtro_FTD', 'filtro_FTD_Voce', 'filtro_FTO', 'filtro_FTO_Voce', 'filtro_Voce_Bilancio', 'riga', 'colonna', 'periodo']}
              size="sm"
            />
          </Col>
        </Row>
      )}

      {/* Modale di successo */}
      <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
        <Modal.Header closeButton>
          <Modal.Title>Upload completed</Modal.Title>
        </Modal.Header>
        <Modal.Body>FinBil raccordi rules loading has finished successfully</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseSuccessModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  token: state.token,
  is_superuser: state.is_superuser === 'false' ? false : true
});

export default connect(mapStateToProps)(ImportFinBilRaccordiRules);