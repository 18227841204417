import React, { useState, useEffect  } from 'react';
import { Container, Card, Form, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import logo from '../assets/logos/FullLogo_Transparent_NoBuffer.png';

export function PasswordReset() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const cardBackgroundColor = 'linear-gradient(90deg, rgba(232,239,242,1) 0%, rgba(227,233,237,1) 39%, rgba(215,221,224,1) 100%)';  // Gradiente
    const activeOuterBorderColor = '#18303e';  // Blu scuro
    const activeInnerBorderColor = '#c95e15';  // Arancione

    useEffect(() => {
        document.body.classList.add('password-reset-page');
        return () => {
            document.body.classList.remove('password-reset-page');
        };
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/api/password-reset/', { email });
            setMessage(response.data.message);
            setError('');
        } catch (err) {
            setError(err.response?.data?.error || 'Si è verificato un errore');
            setMessage('');
        }
    };

    return (
        <Container className='w-50 mt-5'>
            <Card className=''
                style={{
                    border: `2.8px solid ${activeOuterBorderColor}`,
                    background: cardBackgroundColor,
                }}>

                {/* Aggiungi il logo sopra l'header della card */}
                <img 
                    src={logo}
                    alt="Company Logo" 
                    style={{ width: '200px', margin: '10px auto', display: 'block' }}
                />

                <Card.Header style={{ backgroundColor: '#18303e', color: 'white'}}>
                    <h2 className='mb-3'>PumaTools Password Reset</h2>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label style={{ fontWeight: 'bold' }}>Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Insert your e-mail"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                        style={{ backgroundColor: '#fbfcfe' }} />
                        </Form.Group>
                        {message && <Alert variant="success">{message}</Alert>}
                        {error && <Alert variant="danger">{error}</Alert>}
                        <Button 
                            variant="primary" 
                            type="submit" 
                            className="mb-3"
                            style={{ backgroundColor: '#15253f', borderColor: '#15253f' }}
                        >
                            Send reset link
                        </Button>
                    </Form>
                </Card.Body>
                <Card.Footer style={{ backgroundColor: '#c95e15', borderTop: '3px solid #18303e' }}>
                    <Button 
                    variant="link" 
                    type="button" 
                    onClick={() => navigate('/login')}
                    style={{ color: 'white', fontWeight: 'bold' }}
                    >
                        Back to login
                    </Button>
                </Card.Footer>
            </Card>
        </Container>
    );
}