import React, { Component } from 'react';
import { Table, Container,Button } from 'react-bootstrap';
import { variables } from '../Variables';
import UserPermissionsEditor from './UserPermissionsEditor';

// Stili in linea per il testo piccolo
const smallTextStyle = {
  fontSize: '0.6rem'  // Per il corpo della tabella
};

const headerTextStyle = {
  fontSize: '0.6rem',  // Stessa dimensione del corpo
  fontWeight: '600'    // Per mantenerle distinguibili come intestazioni
};

export class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            error: null, // Stato per gestire eventuali errori
        };
        console.log("Users component constructed");
    }

    // Funzione per recuperare la lista utenti dall'API
    refreshList() {
      fetch('/api/users/', { 
          method: 'GET',
          credentials: 'include'  // manteniamo questo che è già presente
      })
      .then(response => {
          if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
      })
      .then(data => {
          this.setState({ users: data, error: null });
      })
      .catch(error => {
          console.error('Errore durante il recupero degli utenti:', error);
          this.setState({ error: 'Errore nel recupero degli utenti' });
      });
  }

    componentDidMount() {
      console.log("Users component mounted");
        this.refreshList();
    }

    handleEditPermissions = (user) => {
      this.setState({
          selectedUser: user,
          showPermissionsEditor: true
      });
  };

    handlePermissionsUpdate = (updatedUser) => {
      this.setState(prevState => ({
          users: prevState.users.map(user =>
              user.id === updatedUser.id ? { ...user, ...updatedUser } : user
          )
      }));
  };

  render() {
    const { users, error, showPermissionsEditor, selectedUser } = this.state;
    return (
        <Container>
            <h2>Lista Utenti</h2>
            {error ? (
                <div className="alert alert-danger">{error}</div>
            ) : users.length === 0 ? (
                <div>Nessun utente trovato.</div>
            ) : (
                <>
                    <Table striped bordered hover className="small-text-table">
                        <thead>
                            <tr style={headerTextStyle}>
                                <th>Id</th>
                                <th>User</th>
                                <th>Email</th>
                                <th>Key</th>
                                <th>ABI</th>
                                <th>Privilege</th>
                                <th>Finrisk</th>
                                <th>Finbil</th>
                                <th>CR Check</th>
                                <th>SDV Check</th>
                                <th>Pillar 3</th>
                                <th>Date Joined</th>
                                <th>Last Login</th>
                                <th>Azioni</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map(user => (
                                <tr key={user.id} style={smallTextStyle}>
                                    <td>{user.id}</td>
                                    <td>{user.username}</td>
                                    <td>{user.email}</td>
                                    <td>{user.key}</td>
                                    <td>{user.abi || 'N/A'}</td>
                                    <td>{user.is_superuser ? 'SuperUser' : 'StandardUser'}</td>
                                    <td>{user.is_finrisk ? 'Sì' : 'No'}</td>
                                    <td>{user.is_finbil ? 'Sì' : 'No'}</td>
                                    <td>{user.is_crcheck ? 'Sì' : 'No'}</td>
                                    <td>{user.is_sdvcheck ? 'Sì' : 'No'}</td>
                                    <td>{user.is_pillar3 ? 'Sì' : 'No'}</td>
                                    <td>{user.is_ownfunds ? 'Sì' : 'No'}</td>
                                    <td>{user.date_joined}</td>
                                    <td>{user.last_login || 'N/A'}</td>
                                    <td>
                                        <Button 
                                            variant="primary" 
                                            size="sm"
                                            onClick={() => this.handleEditPermissions(user)}
                                            style={smallTextStyle}
                                        >
                                            Modifica
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    {selectedUser && (
                        <UserPermissionsEditor
                            key={selectedUser.id} // Aggiungiamo questa riga per forzare l'aggiornamento del componente UserPermissionsEditor
                            show={showPermissionsEditor}
                            user={selectedUser}
                            onHide={() => this.setState({ showPermissionsEditor: false })}
                            onUpdate={this.handlePermissionsUpdate}
                        />
                    )}
                </>
            )}
        </Container>
    );
}
}

export default Users;
