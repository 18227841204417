import React, { useState, useRef, useEffect } from 'react';
import { Modal, Form, Button, Alert } from 'react-bootstrap';
import { Save, X } from 'lucide-react';

const CRNotesForm = ({ 
  show, 
  onHide, 
  onSave, 
  clientData, 
  initialData = null, 
  token,
  isLoading = false 
}) => {
  // Form state
  const [title, setTitle] = useState(initialData?.note_title_text || '');
  const [note, setNote] = useState(initialData?.note_text || '');
  const [error, setError] = useState(null);
  
  // Dragging state
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const modalRef = useRef(null);

  // Handle drag start
  const handleMouseDown = (e) => {
    if (e.target.closest('.modal-header')) {
      setIsDragging(true);
      setDragStart({
        x: e.clientX - position.x,
        y: e.clientY - position.y
      });
    }
  };

  // Handle drag
  const handleMouseMove = (e) => {
    if (isDragging) {
      const newX = e.clientX - dragStart.x;
      const newY = e.clientY - dragStart.y;
      setPosition({ x: newX, y: newY });
    }
  };

  // Handle drag end
  const handleMouseUp = () => {
    setIsDragging(false);
  };

  // Add and remove event listeners
  useEffect(() => {
    if (show) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
      return () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };
    }
  }, [show, isDragging]);

  // Reset form when modal closes
  const handleClose = () => {
    setTitle('');
    setNote('');
    setError(null);
    setPosition({ x: 0, y: 0 }); // Reset position
    onHide();
  };

  // Handle save
  const handleSave = async () => {
    try {
      setError(null);

      if (!title.trim() || !note.trim()) {
        setError('Title and note content are required');
        return;
      }

      console.log("Client data available:", clientData);

      const noteData = {
        abi: clientData.ABI,
        codice_cr_interno: clientData.CODICE_CR_INTERNO,
        codice_censito: clientData['Codice Censito'],
        note_text: note.trim(),
        title_text: title.trim(),
        reference_date: clientData.competenza,
        ndg: clientData.NDG
      };

      console.log("Preparing to save note with data:", noteData);

      await onSave(noteData);
      handleClose();
    } catch (err) {
      setError(err.message || 'Error saving note');
    }
  };

  return (
    <Modal 
      show={show} 
      onHide={handleClose}
      size="lg"
      centered
      ref={modalRef}
      style={{
        transform: `translate(${position.x}px, ${position.y}px)`,
        transition: isDragging ? 'none' : 'transform 0.3s ease-out',
        marginTop: '80px'
      }}
      dialogClassName="modal-draggable"
    >
      <Modal.Header 
        className="bg-[#18303E] text-white flex justify-between items-center cursor-move"
        onMouseDown={handleMouseDown}
      >
        <Modal.Title className="text-xl font-semibold select-none">
          {initialData ? 'Edit Note' : 'Create New Note'}
        </Modal.Title>
        <button
          onClick={handleClose}
          className="bg-transparent hover:bg-gray-700 text-white rounded-full p-2 transition-colors"
          aria-label="Close"
        >
          <X size={24} />
        </button>
      </Modal.Header>

      <Modal.Body className="p-4">
        {error && (
          <Alert variant="danger" className="mb-4">
            {error}
          </Alert>
        )}

        <Form>
          <Form.Group className="mb-4">
            <Form.Label className="font-medium text-gray-700">Note Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter note title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="border-gray-300 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            />
          </Form.Group>

          <Form.Group>
            <Form.Label className="font-medium text-gray-700">Note Content</Form.Label>
            <Form.Control
              as="textarea"
              rows={6}
              placeholder="Enter note content"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              className="border-gray-300 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            />
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer className="flex justify-end gap-3 border-t p-4">
        <Button
          variant="outline-secondary"
          onClick={handleClose}
          disabled={isLoading}
          className="flex items-center gap-2"
        >
          <X size={18} />
          Discard
        </Button>
        <Button
          variant="primary"
          onClick={handleSave}
          disabled={isLoading}
          className="flex items-center gap-2 bg-[#18303E]"
        >
          <Save size={18} />
          Save Note
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CRNotesForm;