import React from 'react';

const SdVCheck = () => {
  return (
    <div>
      <h1>SdVCheck Component</h1>
      {/* Aggiungi il contenuto del componente qui */}
    </div>
  );
};

export default SdVCheck;