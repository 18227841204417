import React, { useState, useEffect,useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { X, Eye, EyeOff, PenLine } from 'lucide-react';
import CRNotesForm from './CRNotesForm';
import NotesSection from './NotesSection';

// Componente per il banner che indica lo stato watchlist
const WatchlistBadge = ({ isWatchlist }) => {
  if (!isWatchlist) return null;
  
  return (
    <div className="bg-yellow-400 text-black px-2 py-1 rounded-md text-sm font-medium ml-4 flex items-center">
      <Eye size={16} className="mr-1" />
      In Watchlist
    </div>
  );
};

// Componente per la visualizzazione di una riga di dati
const DataRow = ({ label, value, multiline = false }) => {
  // Se è multiline (per le descrizioni) manteniamo il layout verticale
  if (multiline) {
    return (
      <div className="mb-6 last:mb-0">
        <div className="font-medium text-gray-700 mb-1">{label}</div>
        <div className="text-gray-600">{value || '-'}</div>
      </div>
    );
  }

  // Per tutti gli altri campi, usiamo un layout orizzontale
  return (
    <div className="mb-6 last:mb-0">
      <div className="flex">
        <span className="font-medium text-gray-700">{label}: </span>
        <span className="text-gray-600 ml-2">{value || '-'}</span>
      </div>
    </div>
  );
};

const ClientOverview = ({ data, show, onHide, token, onWatchlistChange, onDataUpdate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [localWatchlistState, setLocalWatchlistState] = useState(data?.is_watchlist || false);
  const [showNotesForm, setShowNotesForm] = useState(false);
  const [isNoteSaving, setIsNoteSaving] = useState(false);
  const notesSectionRef = useRef(null);
  
  // Gestione watchlist toggle  
  const handleWatchlistToggle = async () => {
    if (isLoading) return;
    setIsLoading(true);
    console.log("handleWatchlistToggle: inizio", {
      currentState: localWatchlistState,
      data: data
    });
    
    try {
      const newWatchlistState = !localWatchlistState;
      console.log("handleWatchlistToggle: richiesta al server", {
        action: newWatchlistState ? 'add' : 'remove',
        abi: data.ABI,
        ndg: data.NDG,
        codice_cr_interno: data.CODICE_CR_INTERNO
      });

      const response = await fetch('/api/cr-watchlist/', {
        method: 'POST',
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: newWatchlistState ? 'add' : 'remove',
          abi: data.ABI,
          ndg: data.NDG,
          codice_cr_interno: data.CODICE_CR_INTERNO,
          codice_censito: data['Codice Censito']
        })
      });

      const result = await response.json();
      console.log("handleWatchlistToggle: risposta server", result);
      
      setLocalWatchlistState(result.is_watchlist);
      console.log("handleWatchlistToggle: stato locale aggiornato", {
        newState: result.is_watchlist
      });

      // Aggiorniamo lo stato locale solo dopo che il callback ha completato
      if (onWatchlistChange) {
        console.log("handleWatchlistToggle: chiamata callback padre");
        await onWatchlistChange();
        console.log("handleWatchlistToggle: callback padre completata");
        setLocalWatchlistState(newWatchlistState);
      }
    } catch (error) {
      console.error('Error toggling watchlist:', error);
      setLocalWatchlistState(data?.is_watchlist || false);
    } finally {
      setIsLoading(false);
    }
  };

  // Funzione di salvataggio delle note
  const handleSaveNote = async (noteData) => {
    try {
      setIsNoteSaving(true);

      // Log dei dati che stiamo per inviare
      console.log("Attempting to save note with data:", noteData);
    
      const response = await fetch('/api/cr-notes/', {
        method: 'POST',
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(noteData)
      });
  
      if (!response.ok) {
        // Log della risposta in caso di errore
        const errorData = await response.json();
        console.error('Server response:', errorData);
        throw new Error(errorData.error || 'Failed to save note');
      }
  
      const result = await response.json();
      console.log("Note saved successfully:", result);

      // Ricarica le note
      if (notesSectionRef.current) {
        await notesSectionRef.current.fetchNotes();
      }
      
      if (onDataUpdate) {
        await onDataUpdate();
      }

      setShowNotesForm(false);
  
    } catch (error) {
      console.error('Error saving note:', error);
      throw error;
    } finally {
      setIsNoteSaving(false);
      setShowNotesForm(false);
    }
  };

  // Determina il titolo da visualizzare
  const displayTitle = data?.INTESTAZIONE || data?.Descrizione_Garantito || 'Client Overview';

  return (
    <Modal 
      show={show} 
      onHide={onHide}
      fullscreen
      dialogClassName="m-0"
    >
      {/*Header*/}
      <Modal.Header className="bg-[#18303E] text-white flex items-center justify-between">
        <div className="flex-1">
          <div className="flex items-center">
            <Modal.Title className="text-xl font-semibold">
              {displayTitle}
            </Modal.Title>
            <WatchlistBadge isWatchlist={localWatchlistState} />
          </div>
          <div className="flex items-center gap-6 mt-2 text-gray-200 text-sm">
            <div className="flex items-center gap-2">
              <span className="font-medium">NDG:</span>
              <span>{data?.NDG || '-'}</span>
            </div>
            <div className="flex items-center gap-2">
              <span className="font-medium">GDR:</span>
              <span>{data?.GDR || '-'}</span>
            </div>
            <div className="flex items-center gap-2">
              <span className="font-medium">GDR DESCR:</span>
              <span>{data?.GDR_DESCR || '-'}</span>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-2">

          {/* Create Note Button */}
          <button
            onClick={() => setShowNotesForm(true)}
            disabled={isLoading}
            className="bg-transparent hover:bg-gray-700 text-white rounded-full p-2 transition-colors"
            title="Create a Note"
          >
            <PenLine size={24} className="text-white" />
          </button>

          {/* Watchlist Button */}
          <button
            onClick={handleWatchlistToggle}
            disabled={isLoading}
            className="bg-transparent hover:bg-gray-700 text-white rounded-full p-2 transition-colors"
            title={localWatchlistState ? 'Remove from Watchlist' : 'Add to Watchlist'}
          >
            {localWatchlistState ? (
              <EyeOff size={24} className="text-yellow-400" />
            ) : (
              <Eye size={24} className="text-white" />
            )}
          </button>

          {/* Close Button */}
          <button 
            onClick={onHide}
            className="bg-transparent hover:bg-gray-700 text-white rounded-full p-2 transition-colors"
            aria-label="Close"
          >
            <X size={24} />
          </button>
        </div>
      </Modal.Header>

      {/* Body del Modal*/}
      <Modal.Body className="p-4 bg-gray-50">
      {/*Wrapper principale con max-width comune*/}
      <div className="max-w-[1600px] mx-auto space-y-6">
        
        {/* Grid per i due box superiori */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          
          {/* Colonna dei dati dell'intermediario, py-padding vertiale, px-padding orizzontale*/}
          <div className="bg-white rounded-lg shadow-md">
            <div className="bg-[#18303E] text-white py-2 p-4 rounded-t-lg">
              <h2 className="text-lg font-semibold">Intermediary Data</h2>
            </div>
            <div className="p-6">
              <DataRow label="CODICE CR INTERNO" value={data?.CODICE_CR_INTERNO} />
              <DataRow label="INTESTAZIONE" value={data?.INTESTAZIONE} />
              <DataRow 
                label="RISCHIO CONFIDI" 
                value={data?.RISCHIO_CONFIDI?.toLocaleString('it-IT', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })} 
              />
              <DataRow 
                label="RISCHIO BANCA" 
                value={data?.RISCHIO_BANCA?.toLocaleString('it-IT', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })} 
              />
              <DataRow 
                label="DUBBIO ESITO" 
                value={data?.DUBBIO_ESITO?.toLocaleString('it-IT', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })} 
              />
              <DataRow label="NR RAPPORTI" value={data?.NR_RAPPORTI} />
              <DataRow label="NUMERO INT AFFIDATI" value={data?.NUMERO_INT_AFFIDATI} />
              <DataRow 
                label="DESCRIZIONE INT AFFIDATI" 
                value={data?.DESCRIZIONE_INT_AFFIDATI}
                multiline 
              />
            </div>
            </div>

          {/* Colonna dei dati della Centrale dei Rischi, py-padding vertiale, px-padding orizzontale */}
          <div className="bg-white rounded-lg shadow-md">
            <div className="bg-[#C95E15] text-white py-2 p-4 rounded-t-lg">
              <h2 className="text-lg font-semibold">CenRis Data</h2>
            </div>
            <div className="p-6">
              <DataRow label="Codice Censito" value={data?.['Codice Censito']} />
              <DataRow label="Descrizione Garantito" value={data?.Descrizione_Garantito} />
              <DataRow 
                label="Importo Garantito" 
                value={data?.['Importo Garantito']?.toLocaleString('it-IT')} 
              />
              <DataRow 
                label="Valore Garanzia" 
                value={data?.['Valore Garanzia']?.toLocaleString('it-IT')} 
              />
              {/* Spazi vuoti per allineamento */}
              <DataRow label="" value="" />
              <DataRow label="" value="" />
              <DataRow 
                label="Numero Intermediari Segnalanti CR" 
                value={data?.['Numero Intermediari Segnalanti CR']} 
              />
              <DataRow 
                label="Descrizione Intermediari CR" 
                value={data?.['Descrizione Intermediari CR']}
                multiline 
              />
            </div>
          </div>
        </div>

          {/* Notes Section, mt--6 é il margine superiore */}
          <div className="bg-white rounded-lg shadow-md mt-6">
            <NotesSection 
              ref={notesSectionRef}
              clientData={data}
              token={token}
              onDataUpdate={onDataUpdate}
            />
          </div>
        </div>

      </Modal.Body>
      
      {/* NotesFormModal */}
      <CRNotesForm
        show={showNotesForm}
        onHide={() => setShowNotesForm(false)}
        onSave={handleSaveNote}
        clientData={data}
        token={token}
        isLoading={isNoteSaving}
      />
    </Modal>
  );
};

export default ClientOverview;