import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Alert, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { DTable } from './DTable';
import ClientOverview from './ClientOverview';
import { ArrowLeft, Eye, Paperclip  } from 'lucide-react';
import { Export } from './Export';

// Shared styles
const styleVariables = {
  headerBackground: '#23485D',
  headerText: '#FFFFFF',
  labelBackground: '#D1D8E8',
  labelBorderOuter: '#18303e',
  labelBorderInner: '#c95e15',
  labelText: '#000000',
  tableHeaderBg: '#2C5B76', // Slightly lighter than headerBackground for contrast
  tableHeaderText: '#FFFFFF',
  tableHeaderHeight: '3.5rem', // Increased header height
  tableBorderColor: '#E2E8F0',
  tableHoverBg: '#F8FAFC',
  tableStripedBg: '#F1F5F9',
};

// Custom styles for the DTable
const tableCustomStyles = {
  '.table': {
    borderCollapse: 'separate',
    borderSpacing: 0,
    width: '100%',
    borderRadius: '8px',
    overflow: 'hidden',
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
  },
  'thead th': {
    backgroundColor: styleVariables.tableHeaderBg,
    color: styleVariables.tableHeaderText,
    height: styleVariables.tableHeaderHeight,
    padding: '1rem',
    fontWeight: '600',
    textTransform: 'uppercase',
    fontSize: '1rem',
    letterSpacing: '0.05em',
    borderBottom: `2px solid ${styleVariables.tableBorderColor}`,
    whiteSpace: 'nowrap',
    position: 'sticky',
    top: 0,
    zIndex: 10,
  },
  'tbody tr': {
    borderBottom: `1px solid ${styleVariables.tableBorderColor}`,
    '&:nth-of-type(odd)': {
      backgroundColor: styleVariables.tableStripedBg,
    },
    '&:hover': {
      backgroundColor: styleVariables.tableHoverBg,
      cursor: 'pointer',
    },
  },
  'tbody td': {
    padding: '0.75rem 1rem',
    fontSize: '1rem',
  },
};

const CrAnalysis = ({ token }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { abi, competenza } = location.state || {};

  const [detailData, setDetailData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showClientOverview, setShowClientOverview] = useState(false);
  const [filteredData, setFilteredData] = useState(null);

  // Callback per la DTable per mantenere il dettaglio dei dati filtrati
  const handleDataFiltered = (newFilteredData) => {
    console.log("Dati filtrati ricevuti:", newFilteredData); // Debug log
    
    // Se newFilteredData è un array, usalo direttamente
    if (Array.isArray(newFilteredData)) {
      setFilteredData(newFilteredData);
      return;
    }
    
    // Se newFilteredData è un oggetto con una proprietà data, usa quella
    if (newFilteredData && Array.isArray(newFilteredData.data)) {
      setFilteredData(newFilteredData.data);
      return;
    }
    
    // Se non abbiamo dati filtrati validi, usa null
    setFilteredData(null);
  };

  // Funzione per caricare i dati di dettaglio
  const loadDetailData = async () => {
    try {
      setLoading(true);
      setError(null);

      console.log("CrAnalysis: inizio caricamento dati");

      // Verifica parametri
      if (!abi || !competenza) {
        throw new Error('Parametri mancanti: ABI e competenza sono richiesti');
      }

      // Costruisci query string
      const params = new URLSearchParams({
        abi,
        competenza: new Date(competenza).toISOString().split('T')[0]
      });

      // Prima carica i dati principali
      const response = await fetch(`/api/cr-detail/?${params.toString()}`, {
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      {/*console.log("CrAnalysis: dati ricevuti dal server:", result);*/}
      
      if (result.status === 'success') {
      // Per ogni record aggiunta la verifica di presenza di note
        const normalizedData = result.data.map(record => {
          console.log('Valori originali del record:', {
            rischio_confidi: record.rischio_confidi,
            rischio_banca: record.rischio_banca,
            valore_garanzia: record.valore_garanzia,
            importo_garantito: record.importo_garantito,
            dubbio_esito: record.dubbio_esito,
          });
       
          const normalized = {
            'Notes': record.has_notes ? (
              <div className="flex justify-center">
                <Paperclip size={20} className="text-gray-600" />
              </div>
            ) : null,
            'Watchlist': record.is_watchlist ? (
              <div className="flex justify-center">
                <Eye size={20} className="text-yellow-400" />
              </div>
            ) : null,
            'CODICE_CR_INTERNO': record.codice_cr_interno,
            'Codice Censito': record.codice_censito,
            'NDG': record.ndg,
            'INTESTAZIONE': record.intestazione,
            'Descrizione_Garantito': record.descrizione_garantito,
            'GDR': record.gdr,
            'GDR_DESCR': record.gdr_descr,
            'RISCHIO_CONFIDI': parseFloat(record.rischio_confidi),
            'RISCHIO_BANCA': parseFloat(record.rischio_banca),
            'DUBBIO_ESITO': parseFloat(record.dubbio_esito),
            'NR_RAPPORTI': record.nr_rapporti,
            'Valore Garanzia': parseFloat(record.valore_garanzia),
            'Importo Garantito': parseFloat(record.importo_garantito),
            'NUMERO_INT_AFFIDATI': record.numero_int_affidati,
            'Numero Intermediari Segnalanti CR': record.numero_intermediari_segnalanti,
            'DESCRIZIONE_INT_AFFIDATI': record.descrizione_int_affidati,
            'Descrizione Intermediari CR': record.descrizione_intermediari_cr,
            'is_watchlist': record.is_watchlist,
            onDoubleClick: () => handleRowDoubleClick(record)
          };
       
          console.log('Record dopo normalizzazione:', normalized);
          return normalized;
        });
       
        console.log('Dataset completo normalizzato:', normalizedData);
        setDetailData(normalizedData);
        // Aggiorna il record selezionato se presente
        if (selectedRecord) {
          const updatedRecord = normalizedData.find(
            record => record.CODICE_CR_INTERNO === selectedRecord.CODICE_CR_INTERNO
          );
          if (updatedRecord) {
            setSelectedRecord(updatedRecord);
          }
        }
       } else {
        throw new Error(result.error || 'Errore nel caricamento dei dati');
       }
    } catch (error) {
      console.error('Error loading detail data:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Gestione del doppio click sulla riga
  const handleRowDoubleClick = (record) => {
    setSelectedRecord(record);
    setShowClientOverview(true);
  };

  // Carica i dati all'avvio
  useEffect(() => {
    if (token && abi && competenza) {
      loadDetailData();
    }
  }, [token, abi, competenza]);

  // Se mancano i parametri, reindirizza al monitoring
  if (!abi || !competenza) {
    navigate('/cr/monitoring');
    return null;
  }

  {/* Padding top (pt), padding laterale (px) e padding bottom (pb) */}
  return (
    <Container fluid className="pt-2 px-4 pb-4">
    
    {/* Contenitore per il pulsante Export */}
    <div className="d-flex justify-content-end mb-3">
      <Export 
        filename="CrAnalysis"
        data={filteredData || detailData || []}
      />
    </div>

      {/* Header con padding verticale (py) e padding orizzontale (px) */}
      <div 
        className="mb-4 py-1 px-4 rounded-lg shadow-lg" 
        style={{ backgroundColor: styleVariables.headerBackground }}
      >
        <Button
          variant="link"
          className="mb-3 text-white p-0 d-flex align-items-center"
          onClick={() => navigate('/cr/monitoring')}
        >
          <ArrowLeft className="me-2" />
          Back to monitoring
        </Button>

        <h4 className="text-white mb-2">CR Detailed Analysis</h4>
        <div className="text-light">
          <div>ABI: {abi}</div>
          <div>Reference date: {new Date(competenza).toLocaleDateString()}</div>
        </div>
      </div>

      {/* Messaggi di errore */}
      {error && (
        <Row className="mb-4">
          <Col>
            <Alert variant="danger">{error}</Alert>
          </Col>
        </Row>
      )}

      {/* Tabella dati */}
      {detailData && (
        <Row>
          <Col>
            <DTable
              data={detailData || []}
              filtered={[
                'Notes',
                'Watchlist',
                'CODICE_CR_INTERNO',
                'Codice Censito',
                'Descrizione_Garantito',
                'NDG',
                'INTESTAZIONE',
                'GDR_DESCR',
                'DESCRIZIONE_INT_AFFIDATI',
                'Descrizione Intermediari CR'
              ]}
              hidden={['onDoubleClick','GDR','RISCHIO_BANCA','DUBBIO_ESITO','Importo Garantito','is_watchlist','NR_RAPPORTI']}
              size="sm"
              onRowDoubleClick={handleRowDoubleClick}
              disableDefaultDoubleClick={true}
              customStyles={tableCustomStyles}
              onDataFiltered={handleDataFiltered}  // Aggiungi questa riga
            />
          </Col>
        </Row>
      )}

      {/* Loading state */}
      {loading && (
        <Row>
          <Col className="text-center">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Caricamento...</span>
            </div>
          </Col>
        </Row>
      )}

{/* Modal ClientOverview */}
{showClientOverview && selectedRecord && (
        <ClientOverview
          data={{
            ...selectedRecord,
            ABI: abi,
            competenza: competenza  
          }}
          show={showClientOverview}
          onHide={() => {
            setShowClientOverview(false);
            setSelectedRecord(null);
          }}
          token={token}
          onWatchlistChange={async () => {
            console.log("CrAnalysis: onWatchlistChange iniziato", {
              currentSelectedRecord: selectedRecord
            });
            
            await loadDetailData();
            console.log("CrAnalysis: dati ricaricati");
            
            const updatedRecord = detailData?.find(
              record => record.CODICE_CR_INTERNO === selectedRecord.CODICE_CR_INTERNO
            );
            console.log("CrAnalysis: record trovato dopo ricaricamento", {
              updatedRecord: updatedRecord
            });
            
            if (updatedRecord) {
              const finalRecord = {
                ...updatedRecord,
                ABI: abi
              };
              console.log("CrAnalysis: aggiornamento selectedRecord", {
                finalRecord: finalRecord
              });
              setSelectedRecord(finalRecord);
            }
          }}
          onDataUpdate={async () => {
            console.log("CrAnalysis: onDataUpdate iniziato");
            
            await loadDetailData();
            console.log("CrAnalysis: dati ricaricati in onDataUpdate");
            
            const updatedRecord = detailData?.find(
              record => record.CODICE_CR_INTERNO === selectedRecord.CODICE_CR_INTERNO
            );
            console.log("CrAnalysis: record trovato dopo ricaricamento in onDataUpdate", {
              updatedRecord: updatedRecord
            });
            
            if (updatedRecord) {
              const finalRecord = {
                ...updatedRecord,
                ABI: abi
              };
              console.log("CrAnalysis: aggiornamento selectedRecord in onDataUpdate", {
                finalRecord: finalRecord
              });
              setSelectedRecord(finalRecord);
            }
          }}
        />
      )}

    </Container>
  );
};

const mapStateToProps = (state) => ({
  token: state.token,
});

export default connect(mapStateToProps)(CrAnalysis);