import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import CrNavTabs from './CrNavTabs';
import CrGuaranteeMonitoring from './CrGuaranteeMonitoring';
import CrGuaranteeDataLoad from './CrGuaranteeDataLoad'; // l'attuale CrCheck rinominato
import CrAnalysis from './CrAnalysis';  // Aggiungiamo questo import

const CrMain = () => {
  const location = useLocation();
  const path = location.pathname.split('/')[2] || 'monitoring'; // prende la parte dopo /cr/

  return (
    <div>
      <CrNavTabs activeTab={path} />
      <Routes>
        <Route path="monitoring" element={<CrGuaranteeMonitoring />} />
        <Route path="data-load" element={<CrGuaranteeDataLoad />} />
        <Route path="analysis" element={<CrAnalysis />} />
        <Route path="" element={<Navigate to="monitoring" replace />} />
      </Routes>
    </div>
  );
};

export default CrMain;