import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { ReactComponent as DbNavigatorIcon } from '../assets/img/db-navigator-icon.svg';
import { ReactComponent as CompareDBIcon } from '../assets/img/comparedb-icon.svg';
import { ReactComponent as CompareSurveyIcon } from '../assets/img/compare-survey-icon.svg';
import { ReactComponent as CrcheckIcon } from '../assets/img/crcheck-icon.svg';
import { ReactComponent as FinriskIcon } from '../assets/img/finrisk-icon.svg';
import { ReactComponent as FinBilIcon } from '../assets/img/finbil-icon.svg';
import { ReactComponent as OwnFundsIcon } from '../assets/img/ownfunds-icon.svg';
import { ReactComponent as SdVCheckIcon } from '../assets/img/sdvcheck-icon.svg';
import { ReactComponent as Pillar3Icon } from '../assets/img/pillar3-icon.svg';
import { ReactComponent as ImportIcon } from '../assets/img/import-icon.svg';
import { ReactComponent as UsersIcon } from '../assets/img/users-icon.svg';
import { ReactComponent as LicensesIcon } from '../assets/img/licenses-icon.svg';

const navigationItems = [
  { name: 'DB Navigator', path: '/db-navigator', icon: DbNavigatorIcon, superuserOnly: false },
  { name: 'CompareDB', path: '/comparedb', icon: CompareDBIcon, superuserOnly: false },
  { name: 'Compare Survey', path: '/compare-survey', icon: CompareSurveyIcon, superuserOnly: false },
  { name: 'CrCheck', path: '/cr', icon: CrcheckIcon, superuserOnly: false, requiresCrCheck: true },
  { name: 'FinRisk', path: '/finrisk', icon: FinriskIcon, superuserOnly: false, requiresFinRisk: true },
  { name: 'FinBil', path: '/finbil', icon: FinBilIcon, superuserOnly: false, requiresFinBil: true },
  { name: 'OwnFunds', path: '/ownfunds', icon: OwnFundsIcon, superuserOnly: false, requiresOwnFunds: true },
  { name: 'SdVCheck', path: '/sdvcheck', icon: SdVCheckIcon, superuserOnly: false, requiresSdVCheck: true },
  { name: 'Pillar3', path: '/pillar3', icon: Pillar3Icon, superuserOnly: false, requiresPillar3: true },

  { 
    name: 'Utilities', 
    icon: ImportIcon, 
    superuserOnly: true,
    isDropdown: true,
    items: [
      { name: 'Import DB Puma', path: '/utilities' },
      { name: 'Import Finbil Rules', path: '/utilities-finbil-rules' }
    ]
  },
  { name: 'Users', path: '/users', icon: UsersIcon, superuserOnly: true },
  { name: 'Licenses', path: '/licenses', icon: LicensesIcon, superuserOnly: true }
];

export function Home({ isSuperuser, isCrCheck, isFinRisk, isFinBil, isOwnFunds, isSdVCheck, isPillar3, user }) {
  console.log("Home component props:", { isSuperuser, isCrCheck, isFinRisk, isFinBil, isOwnFunds, isSdVCheck, isPillar3 });

  // Colori tema
  const colors = {
    background: '#f8fafc', // Sfondo principale più chiaro
    cardEnabled: '#ffffff', // Card bianche per un look moderno
    cardDisabled: '#f1f5f9', // Grigio molto chiaro per card disabilitate
    textEnabled: '#18303e', // Blu scuro per testo
    textDisabled: '#94a3b8', // Grigio medio per testo disabilitato
    accent: '#c95e15', // Arancione accent color
    hoverShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)'
  };

  const isDisabled = (item) => {
    if (isSuperuser) return false;
    if (item.superuserOnly) return true;
    if (item.requiresCrCheck && !isCrCheck) return true;
    if (item.requiresFinRisk && !isFinRisk) return true;
    if (item.requiresFinBil && !isFinBil) return true;
    if (item.requiresOwnFunds && !isOwnFunds) return true;
    if (item.requiresSdVCheck && !isSdVCheck) return true;
    if (item.requiresPillar3 && !isPillar3) return true;

    return false;
  };

  return (
    <div className="min-h-screen py-8" style={{ backgroundColor: colors.background }}>
      <Container>
        {/* Header con effetto gradiente sottile */}
        <div className="text-center mb-8 relative">
          <div 
            className="absolute inset-0 bg-gradient-to-r from-transparent via-blue-50 to-transparent opacity-50"
            style={{ zIndex: 0 }}
          />
          <h1 className="text-4xl font-bold relative" style={{ color: colors.textEnabled }}>
            Welcome{user ? `, ${user}` : ''}
          </h1>
        </div>

        <Row xs={1} md={2} lg={4} className="g-4">
          {navigationItems.map((item, idx) => {
            const isItemDisabled = isDisabled(item);
            
            return (
              <Col key={idx}>
                <Card
                  as={isItemDisabled ? 'div' : Link}
                  to={!isItemDisabled ? (item.isDropdown ? item.items[0].path : item.path) : undefined}
                  className="h-100 transition-all duration-300"
                  style={{
                    backgroundColor: isItemDisabled ? colors.cardDisabled : colors.cardEnabled,
                    cursor: isItemDisabled ? 'not-allowed' : 'pointer',
                    transform: 'translateY(0)',
                    borderRadius: '8px', // Bordo arrotondato
                    border: `3px solid ${colors.textEnabled}`, // Bordo blu che richiama la navbar
                    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
                    textDecoration: 'none',
                    backgroundImage: isItemDisabled 
                      ? 'none' 
                      : 'linear-gradient(90deg, rgba(232,239,242,1) 0%, rgba(227,233,237,1) 39%, rgba(215,221,224,1) 100%)' // Sfondo gradiente
                  }}
                  onMouseEnter={(e) => {
                    if (!isItemDisabled) {
                      e.currentTarget.style.transform = 'translateY(-10px)';
                      e.currentTarget.style.boxShadow = colors.hoverShadow;
                    }
                  }}
                  onMouseLeave={(e) => {
                    if (!isItemDisabled) {
                      e.currentTarget.style.transform = 'translateY(0)';
                      e.currentTarget.style.boxShadow = '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)';
                    }
                  }}
                >
                  {/* Accent bar on top */}
                  <div 
                    className="h-1 w-full rounded-b"
                    style={{ 
                      backgroundColor: isItemDisabled ? colors.textDisabled : colors.accent,
                      opacity: isItemDisabled ? 0.3 : 1
                    }}
                  />
                  
                  <Card.Body className="d-flex flex-column align-items-center justify-content-center p-4">
                    <div className="mb-3">
                      <item.icon 
                        width={40} 
                        height={40}
                        style={{ 
                          opacity: isItemDisabled ? 0.5 : 1,
                          color: isItemDisabled ? colors.textDisabled : colors.textEnabled
                        }}
                      />
                    </div>
                    <Card.Title 
                      className="text-center mb-0 font-medium"
                      style={{ 
                        color: isItemDisabled ? colors.textDisabled : colors.textEnabled,
                        fontSize: '1rem'
                      }}
                    >
                      {item.name}
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
}

export default Home;