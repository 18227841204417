import logo from './assets/img/puma.svg'
import './App.scss';
import React, { useState, useEffect, useMemo, useContext, useCallback, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate, useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { variables } from './Variables';
import { Utilities } from './components/Utilities';
import { Container, Nav, Navbar, NavDropdown, Badge, Button } from "react-bootstrap"
import { connect } from 'react-redux'
import { Licenses } from './components/License';
import Login from './components/Login'
import Signup from './components/Signup'
import * as actions from './store/actions/auth'
import { TabDbNavWrapper } from './components/TabDbNavWrapper';
import { TabCompWrapper } from './components/TabCompWrapper';
import { Users } from './components/Users';
import { DbProvider, DbContext } from './contexts/DbContext';
import { Home } from './components/Home';
import CompareSurvey from './components/CompareSurvey';
import { PasswordReset } from './components/PasswordReset';
import { PasswordResetConfirm } from './components/PasswordResetConfirm';
import FinRisk from './components/FinRisk';
import FinBil from './components/FinBil';
import OwnFunds from './components/OwnFunds';
import SdVCheck from './components/SdVCheck';
import Pillar3 from './components/Pillar3';
import ImportFinBilRules from './components/ImportFinBilRules';
import ImportFinBilRaccordiRules from './components/ImportFinBilRaccordiRules';
import Footer from './components/Footer';
import CrMain from './components/CrMain';
import MFASetup from './components/MFASetup';
import MFAVerify from './components/MFAVerify';


// Componente per gestire gli errori
function ErrorFallback({error}) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
    </div>
  )
}

// Componente funzionale AppContent
function AppContent(props) {
  console.log("AppContent props:", props);
  // Utilizziamo useState per gestire lo stato del componente
  const [state, setState] = useState({
    loaded: { id: -1, name: 'None' },
    dbs: [],
    label: 'Choose DB',
    active: 'home',
    user: '',
    isAuthenticated: localStorage.getItem('token'),
    isSuperuser: localStorage.getItem('is_superuser')
  });

  // uso useRef per creare una variabile isMounted che persiste tra i re-render del componente
  const isMounted = useRef(null);
  // Utilizziamo useContext per accedere al contesto DbContext
  const dbContext = useContext(DbContext);
  // Questo hook serve per ottenere la location corrente
  const location = useLocation();  

  // Stato per gestire i permessi utente
  const [userPermissions, setUserPermissions] = useState({
    isCrCheck: false,
    isFinRisk: false,
    isFinBil: false,
    isOwnFunds: false,
    isSdVCheck: false,
    isPillar3: false,
  });
  
  // Funzione per ottenere le autorizzazioni dell'utente
  const getUserPermissions = useCallback(() => {
    fetch(`${variables.API_URL}user-permissions/`, {
      method: 'GET',
      headers: {
        'Authorization': `Token ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
    })
    .then(response => response.json())
    .then(data => {
      setUserPermissions({
        isCrCheck: data.is_crcheck,
        isFinRisk: data.is_finrisk,
        isFinBil: data.is_finbil,
        isOwnFunds: data.is_ownfunds,
        isSdVCheck: data.is_sdvcheck,
        isPillar3: data.is_pillar3,
      });
    })
    .catch(error => console.error("Error fetching user permissions:", error));
  }, []);

  // Funzione per aggiornare la lista dei database. Esegue una chiamata a DbPuma_db
  const updateDatabases = useCallback(() => {
    console.log("updateDatabases called");
    return fetch('/api/db/', {
      method: 'GET',
      headers: {
        'Authorization': `Token ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      console.log("Fetched databases:", data);
      if (isMounted.current) {
        setState(prevState => ({ ...prevState, dbs: data }));
      }
      return data;
    })
    .catch(error => {
      console.error("Error fetching databases:", error);
      return [];
    });
  }, []);

  useEffect(() => {
    console.log('process.env in App.js:', process.env);
  }, []);

  useEffect(() => {
    isMounted.current = true;

    console.log("App componentDidMount - isAuthenticated:", props.isAuthenticated);
    props.onTryAutoSignup();

    const fetchDatabases = async () => {
      try {
        const data = await updateDatabases();
        if (isMounted.current) {
          setState(prevState => ({ ...prevState, dbs: data }));
        }
      } catch (error) {
        console.error("Error fetching databases:", error);
      }
    };

    fetchDatabases();

    return () => {
      isMounted.current = false;
    };
  }, [props.isAuthenticated, props.onTryAutoSignup, updateDatabases, getUserPermissions]);

  // Funzione per selezionare un database
  const selectDB = useCallback((d) => {
    console.log("App: Selecting DB:", d);
    if (isMounted.current) { 
      setState(prevState => ({
        ...prevState,
        loaded: d,
        label: 'Change DB'
      }));
    }
  }, []);

  // Funzione per gestire la selezione del database
  const handleSetSelectedDb = useCallback((db) => {
    console.log("App: setSelectedDb called with:", db);
    setState(prevState => ({ ...prevState, loaded: db }));
    selectDB(db);
  }, [selectDB]);

  // Funzione per renderizzare la barra di navigazione
  const renderNavbar = () => {
    if (!props.isAuthenticated) return null;
  
    const navLinkStyle = {
      color: 'white',
      fontSize: '0.85rem'  // Aggiungo questa linea per ridurre la dimensione del font
    };
  
    return (
      <Navbar 
        style={{ 
          backgroundColor: '#18303e', // Sfondo della NavBar
          borderBottom: '3px solid #c95e15',  // Aggiunge la riga arancione
        }}
        expand="lg"
      >
      <Navbar.Brand 
        onClick={() => setState(prevState => ({ ...prevState, active: 'home' }))}
        style={{
          ...navLinkStyle, 
          fontSize: '1rem',
          paddingLeft: '20px',  // Aggiungi questo per spostare il brand a destra
        }}
      >
        Puma Tools
      </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {renderNavLinks(navLinkStyle)}
          </Nav>
          {renderNavbarRight(navLinkStyle)}
        </Navbar.Collapse>
      </Navbar>
    );
  };
  
  // Funzione per renderizzare i link di navigazione
  const renderNavLinks = (style) => {
    console.log("renderNavLinks - props:", {
      isAuthenticated: props.isAuthenticated,
      isSuperuser: props.isSuperuser,
      isSuperuser: props.isSuperuser,
      isCrCheck: props.isCrCheck,
      isFinRisk: props.isFinRisk,
      isFinBil: props.isFinBil,
      isOwnFunds: props.isOwnFunds,
      isSdVCheck: props.isSdVCheck,
      isPillar3: props.isPillar3,
      user: props.user
    });
    
    const baseLinks = [
      { to: 'home', label: 'Home' },
      { to: 'db-navigator', label: 'DB Navigator' },
      { to: 'comparedb', label: 'CompareDB' },
      { to: 'compare-survey', label: 'Compare Survey' }
    ];
  
    const superuserLinks = [
      { to: 'utilities', label: 'Utilities' },
      { to: 'users', label: 'Users' },
      { to: 'licenses', label: 'Licenses' }
    ];    

    const conditionalLinks = [
      { to: 'cr', label: 'CRCheck', condition: props.isCrCheck || props.isSuperuser },
      { to: 'finrisk', label: 'FinRisk', condition: props.isFinRisk || props.isSuperuser },
      { to: 'finbil', label: 'FinBil', condition: props.isFinBil || props.isSuperuser },
      { to: 'ownfunds', label: 'OwnFunds', condition: props.isOwnFunds || props.isSuperuser },
      { to: 'sdvcheck', label: 'SdVCheck', condition: props.isSdVCheck || props.isSuperuser },
      { to: 'pillar3', label: 'Pillar3', condition: props.isPillar3 || props.isSuperuser }
    ];
  
    let links = [...baseLinks];
  
    // Aggiungi i link condizionali se l'utente ha le autorizzazioni
    links = links.concat(conditionalLinks.filter(link => link.condition));
  
    // Aggiungi i link del superuser se l'utente è un superuser
    if (props.isSuperuser) {
      console.log("Adding superuser links");
      links = links.concat(superuserLinks);
    } else {
      console.log("Not adding superuser links");
    }
    console.log("Final links:", links);

    // render dello sfondo delle funzioni di navigazione
    return (
      <Nav key='navPages' className="me-auto">
        {links.map(link => {
          if (link.isDropdown) {
            return (
              <NavDropdown 
                key={`nav${link.label}`}
                title={
                  <span style={{ color: 'white',fontSize: '0.85rem' }}>{link.label}</span>  // Modifica qui per il titolo
                }
                style={{
                  color: 'white',
                }}
              >
                {link.items.map(item => (
                  <NavDropdown.Item
                    key={item.path}
                    as={Link}
                    to={item.path}
                    style={{
                      backgroundColor: location.pathname === item.path ? '#c95e15' : 'transparent',
                      color: location.pathname === item.path ? 'white' : 'inherit',
                      fontSize: '0.85rem'  // Ridotto dimensione testo delle voci del menu
                    }}
                  >
                    {item.label}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
            );
          }  

          return (
            <Nav.Link 
              as={Link} 
              key={`nav${link.label}`} 
              to={link.to}
              className={(location.pathname === '/' + link.to || location.pathname === '/utilities-finbil-rules') ? 'active' : ''}
              style={{
                color: 'white',
                fontSize: '0.85rem',
                backgroundColor: (location.pathname === '/' + link.to || 
                                 (link.to === 'utilities' && location.pathname === '/utilities-finbil-rules')) 
                                ? '#c95e15' : 'transparent',
                borderRadius: '5px',
                padding: '8px 12px',
              }}
            >
              {link.label}
            </Nav.Link>
          );
        })}
      </Nav>
    );
  }

  // Funzione per renderizzare la parte destra della navbar
  const renderNavbarRight = (style) => {
    return (
      <Nav key='navDB' className='ml-auto'>
        {renderDatabaseDropdown(style)}
        <Button
          variant='outline-light'
          className='ms-3'
          as={Link}
          to='/'
          onClick={props.onLogout}
          style={{ fontWeight: 'bold' }}
        >
          Logout
        </Button>
      </Nav>
    );
  };
  
  // Funzione per renderizzare il dropdown dei database
  const renderDatabaseDropdown = (style) => {
    return (
      <NavDropdown 
        key='navDd' 
        title={<span style={style}>{state.label}</span>} // Applica lo stile al testo del titolo
        align='end' 
        menuVariant='dark'
      >
        {state.dbs && state.dbs.length > 0 ? (
          state.dbs.map((d) => (
            <NavDropdown.Item 
              key={'navd' + d.name} 
              onClick={() => selectDB(d)}
              style={style}
            >
              {d.name}
            </NavDropdown.Item>
          ))
        ) : (
          <NavDropdown.Item style={style}>No databases available</NavDropdown.Item>
        )}
      </NavDropdown>
    );
  };

  // Funzione per renderizzare le routes
  const renderRoutes = () => {
    console.log("Rendering routes, isSuperuser:", props.isSuperuser);
    const routes = props.isSuperuser ? renderSuperUserRoutes() : renderRegularUserRoutes();
    return <Routes>{routes}</Routes>;
  };

  // Funzione per renderizzare le routes per superuser
  const renderSuperUserRoutes = () => {
    console.log("Rendering SuperUser Routes, isSuperuser:", props.isSuperuser);
    return (
      <>
        <Route exact path='/home' element={renderProtectedRoute(<Home isSuperuser={props.isSuperuser} isCrCheck={props.isCrCheck} isFinRisk={props.isFinRisk} isFinBil={props.isFinBil} user={props.user}/>,false)}/>
        <Route exact path='/db-navigator' element={renderProtectedRoute(<TabDbNavWrapper />)} />
        <Route exact path='/login' element={renderLoginRoute()} />
        <Route exact path='/mfa-setup' element={renderProtectedRoute(<MFASetup />)} />
        <Route exact path='/mfa-verify' element={renderProtectedRoute(<MFAVerify />)} />
        <Route exact path='/comparedb' element={renderProtectedRoute(<TabCompWrapper />)} />
        <Route exact path='/compare-survey' element={renderProtectedRoute(<CompareSurvey />)} />
        <Route exact path='/utilities' element={renderProtectedRoute(<Utilities updateDatabases={updateDatabases} />, true)} />
        <Route exact path='/utilities-finbil-rules' element={renderProtectedRoute(<ImportFinBilRules activeTab="finbil-rules" />, true)} />
        <Route exact path='/utilities-finbil-raccordi-rules' element={renderProtectedRoute(<ImportFinBilRaccordiRules activeTab="finbil-raccordi-rules" />, true)} />
        <Route exact path='/licenses' element={renderProtectedRoute(<Licenses />, true)} />
        <Route exact path='/users' element={renderProtectedRoute(<Users />, true)} />
        <Route exact path="/signup" element={renderSignupRoute()} />
        <Route exact path="/" element={renderDefaultRoute()} />
        <Route exact path='/password-reset' element={<PasswordReset />} />
        <Route exact path='/reset-password/:uid/:token' element={<PasswordResetConfirm />} />
        <Route exact key='pathCr' path='/cr/*' element={renderProtectedRoute(<CrMain />, props.isCrCheck || props.isSuperuser)}/>
        <Route exact key='pathFinRisk' path='/finrisk' element={renderProtectedRoute(<FinRisk />, props.isFinRisk || props.isSuperuser)}/>
        <Route exact key='pathFinBil' path='/finbil' element={renderProtectedRoute(<FinBil />, props.isFinBil || props.isSuperuser)}/>
        <Route exact key='pathOwnFunds' path='/ownfunds' element={renderProtectedRoute(<OwnFunds />, props.isOwnFunds || props.isSuperuser)}/>
        <Route exact key='pathSdVCheck' path='/sdvcheck' element={renderProtectedRoute(<SdVCheck />, props.isSdVCheck || props.isSuperuser)}/>
        <Route exact key='pathPillar3' path='/pillar3' element={renderProtectedRoute(<Pillar3 />, props.isPillar3 || props.isSuperuser)}/>
        <Route path="*" element={<Navigate replace to="/home" />} />
        <Route exact key='pathPasswordReset' path='/password-reset' element={<PasswordReset />} />
        <Route exact key='pathPasswordResetConfirm' path='/reset-password/:uid/:token' element={<PasswordResetConfirm />} />
        <Route path="*" element={<Navigate replace to="/login" />} />
        <Route path="*" element={<div>404 - Not Found: {window.location.pathname}</div>} />
      </>
    );
  };

  // Funzione per renderizzare le routes per utenti normali
  const renderRegularUserRoutes = () => {
    return (
      <>
        <Route exact path='/home' element={renderProtectedRoute(<Home isSuperuser={props.isSuperuser} isCrCheck={props.isCrCheck} isFinRisk={props.isFinRisk} isFinBil={props.isFinBil} user={props.user}/>,false)}/>
        <Route exact key='pathDbNavigator' path='/db-navigator' element={renderProtectedRoute(<TabDbNavWrapper />)} />
        <Route exact key='pathLogin' path='/login' element={renderLoginRoute()} />
        <Route exact path='/mfa-setup' element={renderProtectedRoute(<MFASetup />)} />
        <Route exact path='/mfa-verify' element={renderProtectedRoute(<MFAVerify />)} />
        <Route exact key='pathCompareDB' path='/comparedb' element={renderProtectedRoute(<TabCompWrapper />)} />
        <Route exact key='pathCompareSurvey' path='/compare-survey' element={renderProtectedRoute(<CompareSurvey />)} />
        <Route exact key='pathSignup' path="/signup" element={renderSignupRoute()} />
        <Route exact key='pathDefault' path="/" element={renderDefaultRoute()} />
        <Route exact key='pathPasswordReset' path='/password-reset' element={<PasswordReset />} />
        <Route exact key='pathPasswordResetConfirm' path='/reset-password/:uid/:token' element={<PasswordResetConfirm />} />
        <Route exact key='pathCr' path='/cr/*' element={renderProtectedRoute(<CrMain />, false, true, false)}/>
        <Route exact key='pathFinRisk' path='/finrisk' element={renderProtectedRoute(<FinRisk />, false, true, false)}/>
        <Route exact key='pathFinBil' path='/finbil' element={renderProtectedRoute(<FinBil />, false, false, true)}/>
        <Route exact key='pathOwnFunds' path='/ownfunds' element={renderProtectedRoute(<OwnFunds />, false, false, true)}/>
        <Route exact key='pathSdVCheck' path='/sdvcheck' element={renderProtectedRoute(<SdVCheck />, false, false, true)}/>
        <Route exact key='pathPillar3' path='/pillar3' element={renderProtectedRoute(<Pillar3 />, false, false, true)}/>
        <Route exact key='pathPasswordReset' path='/password-reset' element={<PasswordReset />} />
        <Route exact key='pathPasswordResetConfirm' path='/reset-password/:uid/:token' element={<PasswordResetConfirm />} />
        <Route path="*" element={<Navigate replace to="/login" />} />
        <Route path="*" element={<div>404 - Not Found: {window.location.pathname}</div>} />
      </>
    );
  };

  // Funzione per renderizzare una route protetta
  const renderProtectedRoute = (component, requiresSuperuser = false, requiresCrCheck = false, requiresFinRisk = false, requiresFinBil = false, requiresOwnFunds = false, requiresSdVCheck = false, requiresPillar3 = false) => {
    console.log("Rendering protected route:", {
      path: window.location.pathname,
      isAuthenticated: props.isAuthenticated, 
      isSuperuser: props.isSuperuser,
      isCrCheck: props.isCrCheck,
      isFinRisk: props.isFinRisk,
      isFinBil: props.isFinBil,
      isOwnFunds: props.isOwnFunds,
      isSdVCheck: props.isSdVCheck,
      isPillar3: props.isPillar3,
      requiresSuperuser,
      requiresCrCheck,
      requiresFinRisk,
      requiresFinBil,
      requiresOwnFunds,
      requiresSdVCheck,
      requiresPillar3,
      component: component.type.name
    });
  
    if (!props.isAuthenticated) {
      console.log("Not authenticated, redirecting to login");
      return <Navigate replace to='/login' />;
    }
    
    if (requiresSuperuser && !props.isSuperuser) {
      console.log("Not a superuser, redirecting to home");
      return <Navigate replace to='/home' />;
    }

    if (requiresCrCheck && !props.isCrCheck) {
      console.log("CrCheck access denied, redirecting to home");
      return <Navigate replace to='/home' />;
    }

    if (requiresFinRisk && !props.isFinRisk) {
      console.log("FinRisk access denied, redirecting to home");
      return <Navigate replace to='/home' />;
    }
    
    if (requiresFinBil && !props.isFinBil) {
      console.log("FinBil access denied, redirecting to home");
      return <Navigate replace to='/home' />;
    }

    if (requiresOwnFunds && !props.isOwnFunds) {
      console.log("OwnFunds access denied, redirecting to home");
      return <Navigate replace to='/home' />;
    }

    if (requiresSdVCheck && !props.isSdVCheck) {
      console.log("SdvCheck access denied, redirecting to home");
      return <Navigate replace to='/home' />;
    }

    if (requiresPillar3 && !props.isPillar3) {
      console.log("Pillar3 access denied, redirecting to home");
      return <Navigate replace to='/home' />;
    }
  
    console.log("Rendering component:", component.type.name);
    return component;
  };

  // Funzione per renderizzare la route di login
  const renderLoginRoute = () => {
    return props.isAuthenticated ? <Navigate replace to='/home' /> : <Login {...props} />;
  };

  // Funzione per renderizzare la route di signup
  const renderSignupRoute = () => {
    return !props.isAuthenticated ? <Signup /> : <Navigate replace to='/home' />;
  };

  // Funzione per renderizzare la route di default
  const renderDefaultRoute = () => {
    return !props.isAuthenticated ? <Navigate replace to="/login" /> : <Navigate replace to='/home' />;
  };

  // Utilizziamo useMemo per memorizzare il valore del provider
  const providerValue = useMemo(() => ({
    dbs: state.dbs,
    selectedDb: state.loaded,
    setSelectedDb: selectDB,
    refreshDbs: updateDatabases
  }), [state.dbs, state.loaded, selectDB, updateDatabases]);

  // Log per debugging
  console.log("App render - isAuthenticated:", props.isAuthenticated);
  console.log("App render - state dbs:", state.dbs);
  console.log("App render - state loaded:", state.loaded);
  console.log("App: Rendering with providerValue:", providerValue);

   // Nuova riga di debugging
   console.log("App props:", {
    isAuthenticated: props.isAuthenticated,
    isSuperuser: props.isSuperuser,
    isCrCheck: props.isCrCheck,
    isFinRisk: props.isFinRisk,
    isFinBil: props.isFinBil,
    isOwnFunds: props.isOwnFunds,
    isSdVCheck: props.isSdVCheck,
    isPillar3: props.isPillar3,
    user: props.user
  });

  // Rendering del componente
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <DbProvider value={providerValue}>
        <div className='App'>
          {renderNavbar()}
          <div style={{ paddingBottom: '40px' }}>  {/* Aggiungi padding per il footer */}
            {renderRoutes()}
          </div>
          <Footer />
        </div>
      </DbProvider>
    </ErrorBoundary>
  );
}

// Componente funzionale App
function App(props) {
  return (
    <Router>
      <AppContent {...props} />
    </Router>
  );
}

// Funzione per mappare lo stato Redux alle props del componente
const mapStateToProps = state => {
  console.log("mapStateToProps - state:", state);
  return {
    isAuthenticated: state.token !== null,
    user: state.user,
    isSuperuser: Boolean(state.is_superuser), // Converto in booleano
    isCrCheck: Boolean(state.is_crcheck),     // Converto in booleano
    isFinRisk: Boolean(state.is_finrisk),     // Converto in booleano
    isFinBil: Boolean(state.is_finbil),        // Converto in booleano
    isOwnFunds: Boolean(state.is_ownfunds),        // Converto in booleano
    isSdvCheck: Boolean(state.is_sdvcheck),        // Converto in booleano
    isPillar3: Boolean(state.is_pillar3)        // Converto in booleano
  };
}

// Funzione per mappare le azioni Redux alle props del componente
const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
    onLogout: () => dispatch(actions.logout())
  }
}

// Esportiamo il componente connesso a Redux
export default connect(mapStateToProps, mapDispatchToProps)(App);