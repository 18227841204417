import React, { useState, useEffect } from 'react';
import { Container, Button, Form, Row, Col, Spinner, Alert, ProgressBar, Modal } from 'react-bootstrap';
import { Export } from './Export';
import { connect } from 'react-redux';
import { DTable } from './DTable';
import UtilitiesNavTabs from './UtilitiesNavTabs';

/* Riutilizziamo gli stessi stili di FinBil */
const styleVariables = {
  labelBackground: '#18303e',
  labelBorderOuter: '#18303e',
  labelBorderInner: '#c95e15',
  labelText: '#FFFFFF',
  browseButtonBackground: 'transparent',
  browseButtonBorder: '#18303E',
  browseButtonText: '#18303E',
  commonWidth: '180px',
  commonHeight: '30px',
  fontSize: '0.70rem',
  buttonFontSize: '0.70rem'
};

const labelStyles = {
  backgroundColor: styleVariables.labelBackground,
  border: `2.3px solid ${styleVariables.labelBorderOuter}`,
  boxShadow: `inset 0 0 0 1.8px ${styleVariables.labelBorderInner}`,
  height: styleVariables.commonHeight,
  width: styleVariables.commonWidth,
  color: styleVariables.labelText,
  buttonFontSize: '0.70rem'
};

const browseButtonStyles = {
  width: styleVariables.commonWidth,
  height: styleVariables.commonHeight,
  backgroundColor: styleVariables.browseButtonBackground,
  color: styleVariables.browseButtonText,
  borderColor: styleVariables.browseButtonBorder,
  fontSize: styleVariables.buttonFontSize
};

const ImportFinBilRules = (props) => {
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [resultData, setResultData] = useState(null);
  const [totalRules, setTotalRules] = useState(0);
  const [selectedApplication, setSelectedApplication] = useState('FINBIL');

  // Stati per il polling del progresso
  const [uploadProgress, setUploadProgress] = useState(0);
  const [jobId, setJobId] = useState(null);
  
  // Stato che indica successo nell'elaborazione
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  // Lista di applicazioni per il dropdown
  const APPLICATIONS = [
    { value: 'FINBIL', label: 'FinBil' },
    { value: 'OWNFUNDS', label: 'Own Funds' },
    { value: 'PILLAR3', label: 'Pillar 3' },
    { value: 'SDVCHECK', label: 'SdV Check' }
  ];

  // Chiamata API per vedere la tabella delle regole
useEffect(() => {
  const fetchRules = async () => {
    try {
      const response = await fetch(`/api/get-bil-rules/?application=${selectedApplication}`, {
        headers: {
          'Authorization': `Token ${props.token}`
        },
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setResultData(data.rules);
      setTotalRules(data.rules.length); // Hook per ottenere il numero di righe relative alle regole
    } catch (error) {
      setError('Errore nel caricamento delle regole: ' + error.message);
    }
  };

  fetchRules();
}, [props.token, selectedApplication]); // Aggiunto selectedApplication alle dipendenze

  // Gestione del file
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) return;

    // Verifica estensione
    const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
    if (!['xlsx', 'xls'].includes(fileExtension)) {
      setError('Il file deve essere in formato Excel (.xlsx o .xls)');
      return;
    }

    // Verifica nome file
    if (selectedFile.name.split('.')[0] !== 'REGOLE_BILANCIO') {
      setError('Il nome del file deve essere REGOLE_BILANCIO');
      return;
    }

    setFile(selectedFile);
    setError(null);
  };

  // Gestione dell'invio
  const handleStartQuery = async () => {
    if (!file) {
      setError('Selezionare un file prima di procedere');
      return;
    }

    if (!props.token) {
      setError('Sessione scaduta. Effettua nuovamente il login.');
      return;
    }

    setIsLoading(true);
    setError(null);
    setUploadProgress(0);

    const formData = new FormData();
    formData.append('rules_file', file);

    try {
      const headers = {
        'Authorization': `Token ${props.token}`
      };

      const response = await fetch('/api/upload-finbil-rules/', {
        method: 'POST',
        headers: headers,
        credentials: 'include',
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setJobId(data.job_id);
      startPolling(data.job_id);
    } catch (error) {
      console.error('Error:', error);
      setError('Errore durante l\'importazione: ' + error.message);
      setIsLoading(false);
    }
};

// Funzione per il polling del progresso
const startPolling = (jobId) => {
  const pollInterval = setInterval(async () => {
      try {
          const response = await fetch(`/api/finbil-rules-status/${jobId}/`, {
              headers: {
                  'Authorization': `Token ${props.token}`
              },
              credentials: 'include'
          });

          if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          
          if (data.status === 'completed') {
              clearInterval(pollInterval);
              setIsLoading(false);
              setShowSuccessModal(true);
              // Ricarica i dati della tabella
              const response = await fetch('/api/get-bil-rules/', {
                  headers: {
                      'Authorization': `Token ${props.token}`
                  },
                  credentials: 'include'
              });
              const rulesData = await response.json();
              setResultData(rulesData.rules);
              setTotalRules(rulesData.rules.length); // Aggiorniamo anche il conteggio totale
          } else if (data.status === 'error') {
              clearInterval(pollInterval);
              setError(data.error);
              setIsLoading(false);
          } else {
              // Aggiorna il progresso
              setUploadProgress(Math.round((data.processed_rows / data.total_rows) * 100));
          }
      } catch (error) {
          clearInterval(pollInterval);
          setError('Errore durante il monitoraggio: ' + error.message);
          setIsLoading(false);
      }
  }, 100);
};

// Funzione per gestire il successo nell'elaborazione
const handleCloseSuccessModal = () => {
  setShowSuccessModal(false);
};
  return (
    <Container fluid className="p-4">
      <UtilitiesNavTabs />
      {/* Barra delle azioni */}
      <Row className="mb-4">
        <Col className="d-flex justify-content-end gap-3">
          <Form.Select 
            value={selectedApplication}
            onChange={(e) => setSelectedApplication(e.target.value)}
            style={{ width: '150px' }}
          >
            {APPLICATIONS.map(app => (
              <option key={app.value} value={app.value}>
                {app.label}
              </option>
            ))}
          </Form.Select>  
          <Button 
            onClick={handleStartQuery} 
            variant="success"
            disabled={isLoading}
            style={{ width: '150px' }}
          >
            {isLoading ? <Spinner animation="border" size="sm" /> : 'Upload Rules'}
          </Button>
          <Export 
            filename='FinBil_Rules'
            data={resultData || []}
            disabled={!resultData}
            style={{ width: '150px' }}
          />
        </Col>
      </Row>

      {/* Total Rules Counter */}
      <Row className="mb-3">
        <Col>
          <div className="d-flex align-items-center">
            <div 
              className="p-2 rounded d-flex justify-content-center align-items-center me-3"
              style={{...labelStyles,
              color: styleVariables.labelText
              }}
            >
              <span className="fw-bold">Total Rules</span>
            </div>
            <div 
              className="p-2 rounded d-flex justify-content-center align-items-center"
              style={{
                ...browseButtonStyles,
                backgroundColor: '#f8f9fa',
                cursor: 'default',
                border: '1px solid #18303E',  // Aggiunto lo stesso bordo del pulsante Browse
                textTransform: 'uppercase'     // Rende il numero maiuscolo
              }}
            >
              {totalRules}
            </div>
          </div>
        </Col>
      </Row>

      {/* File input */}
      <Row className="mb-4">
        <Col>
          <div className="d-flex align-items-center">
            <div 
              className="p-2 rounded d-flex justify-content-center align-items-center me-3"
              style={{...labelStyles,
                color: styleVariables.labelText
                }}
            >
              <Form.Label className="fw-bold mb-0">
                Bilancio Rules
              </Form.Label>
            </div>
            <div className="d-flex align-items-center">
              <input
                type="file"
                accept=".xlsx,.xls"
                onChange={handleFileChange}
                style={{ display: 'none' }}
                id="rules-file"
              />
              <Button 
                as="label" 
                htmlFor="rules-file"
                variant="outline-primary"
                className="me-3"
                style={browseButtonStyles}
              >
                Browse
              </Button>
              <span className="text-muted" style={{ fontSize: styleVariables.fontSize }}>
                {file ? file.name : 'No file selected'}
              </span>
            </div>
          </div>
        </Col>
      </Row>

      {/* Progress Bar */}
      {isLoading && (
        <Row className="mt-3">
            <Col>
                <div className="d-flex align-items-center">
                    <div className="w-100">
                        <ProgressBar 
                            now={uploadProgress} 
                            label={`${uploadProgress}%`} 
                            animated
                        />
                    </div>
                </div>
            </Col>
        </Row>
      )}

      {/* Messaggi di errore */}
      {error && (
        <Row className="mt-4">
          <Col>
            <Alert variant="danger">{error}</Alert>
          </Col>
        </Row>
      )}

      {/* Tabella risultati */}
      {resultData && (
        <Row className="mt-4">
          <Col>
            <DTable
              data={resultData}
              filtered={['codice_regola', 'misura_output', 'riga', 'colonna', 'periodo']}
              size="sm"
            />
          </Col>
        </Row>
      )}
      {/* Modale di successo */}
      <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
        <Modal.Header closeButton>
          <Modal.Title>Upload completed</Modal.Title>
        </Modal.Header>
        <Modal.Body>FinBil rules loading has finished successfully</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseSuccessModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  token: state.token,
  is_superuser: state.is_superuser === 'false' ? false : true
});

export default connect(mapStateToProps)(ImportFinBilRules);