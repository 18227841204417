import React, { useState, useEffect } from 'react';
import { Container, Card, Form, Button, Alert } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import logo from '../assets/logos/FullLogo_Transparent_NoBuffer.png';


export function PasswordResetConfirm() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { uid, token } = useParams();
    const cardBackgroundColor = '#d9e0ea';  // In questo caso grigio chiaro
    const activeOuterBorderColor = '#18303e';  // Blu scuro
    const activeInnerBorderColor = '#c95e15';  // Arancione

    useEffect(() => {
        document.body.classList.add('password-reset-confirm-page');
        return () => {
            document.body.classList.remove('password-reset-confirm-page');
        };
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setError('Le password non coincidono');
            return;
        }
        try {
            const response = await axios.post('/api/password-reset-confirm/', { uid, token, new_password: password });
            setMessage(response.data.message);
            setError('');
            setTimeout(() => navigate('/login'), 3000);
        } catch (err) {
            setError(err.response?.data?.error || 'Si è verificato un errore');
            setMessage('');
        }
    };

    return (
        <Container className='w-50 mt-5'>
            <Card className=''
                style={{
                    border: `2.5px solid ${activeOuterBorderColor}`,
                    boxShadow: `inset 0 0 0 1px ${activeInnerBorderColor}`,
                    backgroundColor: cardBackgroundColor,
                }}>
                
                {/* Aggiungi il logo sopra l'header della card */}
                <img 
                    src={logo}
                    alt="Company Logo" 
                    style={{ width: '200px', margin: '10px auto', display: 'block' }}
                />

                <Card.Header style={{ backgroundColor: '#18303e', color: 'white'}}>
                    <h2 className='mb-3'>PumaTools Password Reset</h2>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label style={{ fontWeight: 'bold' }}>New Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Insert new password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                        style={{ backgroundColor: '#fbfcfe' }} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
                            <Form.Label style={{ fontWeight: 'bold' }}>Confirm Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Confirm new password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                        style={{ backgroundColor: '#fbfcfe' }} />
                        </Form.Group>
                        {message && <Alert variant="success">{message}</Alert>}
                        {error && <Alert variant="danger">{error}</Alert>}
                        <Button 
                            variant="primary" 
                            type="submit" 
                            className="mb-3"
                            style={{ backgroundColor: '#15253f', borderColor: '#15253f' }}
                        >
                            Reset Password
                        </Button>
                    </Form>
                </Card.Body>
                <Card.Footer style={{ backgroundColor: '#c95e15', borderTop: '3px solid #18303e', height: '60px'  }}>
                </Card.Footer>
            </Card>
        </Container>
    );
}