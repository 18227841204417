import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const UtilitiesNavTabs = () => {
  // Hook per la navigazione tra le pagine
  const navigate = useNavigate();
  // Hook per ottenere l'attuale location/URL
  const location = useLocation();
  
  // Determina quale tab è attivo in base al pathname corrente
  // isFinbilRules sarà true solo se il pathname include 'finbil-rules' ma NON include 'raccordi'
  const isFinbilRules = location.pathname.includes('finbil-rules') && !location.pathname.includes('raccordi');
  // isFinbilRaccordiRules sarà true se il pathname include 'finbil-raccordi-rules'
  const isFinbilRaccordiRules = location.pathname.includes('finbil-raccordi-rules');
  
  // Determina il tab corrente in base alle condizioni
  const currentTab = isFinbilRaccordiRules ? 'finbil-raccordi-rules' : 
                    isFinbilRules ? 'finbil-rules' : 'utilities';

  // Stile comune per tutti i tab
  const getTabStyle = (tabName) => ({
    backgroundColor: currentTab === tabName ? '#23485D' : '#eee',
    color: currentTab === tabName ? 'white' : '#6c757d',
    fontWeight: currentTab === tabName ? 'bold' : 'normal',
    borderBottom: currentTab === tabName ? 'none' : '1px solid #dee2e6',
    borderColor: currentTab === tabName ? '#18303e' : '#dee2e6',
    borderStyle: 'solid',
    borderWidth: '1px',
    marginBottom: '-1px',
    zIndex: currentTab === tabName ? '1' : 'auto'
  });

  return (
    <div className="mb-4">
      <nav className="nav nav-tabs">
        {/* Tab per Import DB Puma */}
        <button 
          className={`nav-link ${currentTab === 'utilities' ? 'active' : ''}`}
          onClick={() => navigate('/utilities')}
          style={getTabStyle('utilities')}
        >
          Import DB Puma
        </button>

        {/* Tab per Import Finbil Rules */}
        <button 
          className={`nav-link ${currentTab === 'finbil-rules' ? 'active' : ''}`}
          onClick={() => navigate('/utilities-finbil-rules')}
          style={getTabStyle('finbil-rules')}
        >
          Import Finbil Rules
        </button>

        {/* Nuovo tab per Import Finbil Raccordi Rules */}
        <button 
          className={`nav-link ${currentTab === 'finbil-raccordi-rules' ? 'active' : ''}`}
          onClick={() => navigate('/utilities-finbil-raccordi-rules')}
          style={getTabStyle('finbil-raccordi-rules')}
        >
          Import Finbil Raccordi Rules
        </button>
      </nav>
    </div>
  );
};

export default UtilitiesNavTabs;