import React, { useState } from 'react';
import { Container, Card, Form, Button, Alert } from 'react-bootstrap';
import logo from '../assets/logos/FullLogo_Transparent_NoBuffer.png';
import { useDispatch } from 'react-redux';
import { authSuccess } from '../store/actions/auth';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { checkAuthTimeout } from '../store/actions/auth';


export default function MFAVerify({ onVerificationComplete }) {
    const dispatch = useDispatch();
    const [code, setCode] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const user = useSelector(state => state.user);

    // Stili consistenti con Login
    const cardBackgroundColor = 'linear-gradient(90deg, rgba(232,239,242,1) 0%, rgba(227,233,237,1) 39%, rgba(215,221,224,1) 100%)';
    const activeOuterBorderColor = '#18303e';

    const verifyCode = async () => {
        try {
            const response = await fetch('/api/verify-2fa/', {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${localStorage.getItem('temp_token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ code })
            });
            
            const data = await response.json();
            console.log('MFA Verify Response:', data);
    
            if (response.ok) {
                // Salvataggio nel localStorage
                localStorage.setItem('token', data.token);
                localStorage.setItem('expirationDate', new Date(new Date().getTime() + 3600 * 1000));
                localStorage.setItem('user', data.user.username);
                localStorage.setItem('is_superuser', data.user.is_superuser);
                localStorage.setItem('is_crcheck', data.user.is_crcheck);
                localStorage.setItem('is_finrisk', data.user.is_finrisk);
                localStorage.setItem('is_finbil', data.user.is_finbil);
    
                console.log("Valori salvati nel localStorage dopo MFA:", {
                    token: data.token,
                    username: data.user.username,
                    is_superuser: data.user.is_superuser,
                    is_crcheck: data.user.is_crcheck,
                    is_finrisk: data.user.is_finrisk,
                    is_finbil: data.user.is_finbil
                });
    
                // Aggiornamento stato Redux
                dispatch(authSuccess(
                    data.token,
                    data.user.username,
                    data.user.is_superuser,
                    data.user.is_crcheck,
                    data.user.is_finrisk,
                    data.user.is_finbil
                ));
    
                // Timeout autenticazione
                dispatch(checkAuthTimeout(3600));
    
                // Navigazione alla home
                navigate('/home');
            } else {
                setError(data.message || "Errore durante la verifica del codice MFA");
            }
        } catch (err) {
            console.error('Verification error:', err);
            setError("Errore durante la verifica del codice MFA. Riprova.");
        }
    };

    return (
        <Container className='w-50 mt-5'>
            <Card style={{
                border: `2.8px solid ${activeOuterBorderColor}`,
                background: cardBackgroundColor,
            }}>
                <img 
                    src={logo}
                    alt="Company Logo" 
                    style={{ width: '200px', margin: '10px auto', display: 'block' }}
                />
                
                <Card.Header style={{ backgroundColor: '#18303e', color: 'white'}}>
                    <h2 className='mb-3'>Verifica Autenticazione</h2>
                </Card.Header>

                <Card.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label style={{ fontWeight: 'bold' }}>
                                Codice Google Authenticator
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                                placeholder="Inserisci il codice"
                                style={{ backgroundColor: '#fbfcfe' }}
                            />
                        </Form.Group>
                        <Button 
                            onClick={verifyCode}
                            className="mt-3"
                            style={{ backgroundColor: '#15253f', borderColor: '#15253f' }}
                        >
                            Verifica
                        </Button>
                    </Form>
                    
                    {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
                </Card.Body>
            </Card>
        </Container>
    );
}
